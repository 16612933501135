import { GET_LOCALFORM_DATA } from '../../constants/ActionTypes';

export function getLocalFormData(url, params, subrequest, endpoint) {
  const qs = Object.keys(params)
    .map(function (key) {
      return key + '=' + params[key];
    })
    .join('&');
  const path = `${url}/${endpoint ? endpoint : 'formdata'}?${qs}`;
  return {
    type: GET_LOCALFORM_DATA,
    subrequest,
    request: {
      op: 'get',
      path: `${path}`,
      data: params,
    },
  };
}
