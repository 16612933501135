import { cloneDeep } from 'lodash';
// VLibras
import Libras from '@plonegovbr/volto-vlibras/components/Libras';

// Widgets
import { CEPWidget } from './components/Widgets/CEPWidget';
import { CPFWidget } from './components/Widgets/CPFWidget';
import { CNPJWidget } from './components/Widgets/CNPJWidget';
import JSONWidget from './components/Widgets/JSONWidget';
import { TelefoneWidget } from './components/Widgets/TelefoneWidget';

// Views
import AreaView from './components/Views/AreaView';
import ColaboradorView from './components/Views/ColaboradorView';
import ColaboradoresView from './components/Views/ColaboradoresView';
import ContratacaoView from './components/Views/Licitacao/ContratacaoView';
import ContratoView from './components/Views/Licitacao/ContratoView';
import DocumentoView from './components/Views/Licitacao/DocumentoView';
import DiariaView from './components/Views/DiariaView';
import FornecedorView from './components/Views/FornecedorView';
import ESICView from './components/Views/ESICView';
import DemonstracaoContabilView from './components/Views/DemonstracaoContabilView';
import DuodecimoView from './components/Views/DuodecimoView';
import InformacaoEmprelView from './components/Views/InformacaoEmprel';
import InformacaoCeapView from './components/Views/InformacaoCeap';
import ObraView from './components/Views/ObraView';
import PagamentoView from './components/Views/PagamentoView';
import PastaPesquisaView from './components/Views/PastaPesquisaView';
import PrestacaoContasView from './components/Views/PrestacaoContasView';
import GestaoFiscalView from './components/Views/GestaoFiscalView';
import JulgamentoContasView from './components/Views/JulgamentoContasView';
import ConvenioView from './components/Views/ConvenioView';
import ConcursoPublicoView from './components/Views/ConcursoPublicoView';
import AdesaoAtaView from './components/Views/AdesaoAtaView';
import InformacaoSigilosaView from './components/Views/InformacaoSigilosaView';

// Legislativa
import ComissaoView from './components/Views/InformacaoLegislativa/ComissaoView';
import LegislaturaView from './components/Views/InformacaoLegislativa/LegislaturaView';
import MesaDiretoraView from './components/Views/InformacaoLegislativa/MesaDiretoraView';
import MateriaView from './components/Views/InformacaoLegislativa/MateriaView';
import NormaView from './components/Views/InformacaoLegislativa/NormaView';
import VereadorView from './components/Views/InformacaoLegislativa/VereadorView';
import SessaoPlenariaView from './components/Views/InformacaoLegislativa/SessaoPlenariaView';

// Blocks
/// ExportData
import ExportDataView from './components/Blocks/ExportData/View';
import exportSVG from '@plone/volto/icons/download.svg';

/// Contato
import ContatoBlockEdit from './components/Blocks/Contato/Edit';
import ContatoBlockView from './components/Blocks/Contato/View';
import contatoSVG from '@plone/volto/icons/user.svg';

/// Areas
import AreasBlockEdit from './components/Blocks/Areas/Edit';
import AreasBlockView from './components/Blocks/Areas/View';
import areasSVG from '@plone/volto/icons/home.svg';

/// Colaboradores
import ColaboradoresBlockEdit from './components/Blocks/Colaboradores/Edit';
import ColaboradoresBlockView from './components/Blocks/Colaboradores/View';
import colaboradoresSVG from '@plone/volto/icons/group.svg';

/// Localizacao
import LocalizacaoBlockEdit from './components/Blocks/Localizacao/Edit';
import LocalizacaoBlockView from './components/Blocks/Localizacao/View';
import locationSvg from './icons/location-dot-solid.svg';

/// Vereador
import VereadorBlockEdit from './components/Blocks/Vereador/Edit';
import VereadorBlockView from './components/Blocks/Vereador/View';
import vereadorSVG from '@plone/volto/icons/user.svg';

/// Diarias
import DiariasEdit from './components/Blocks/Diarias/Edit';
import DiariasView from './components/Blocks/Diarias/View';
import diariasSVG from '@plone/volto/icons/form.svg';

/// Fiscais de contratos
import FiscaisEdit from './components/Blocks/Fiscais/Edit';
import FiscaisView from './components/Blocks/Fiscais/View';
import fiscaisSVG from '@plone/volto/icons/form.svg';

/// LocalForm
import LocalFormEdit from './components/Blocks/LocalForm/Edit';
import LocalFormView from './components/Blocks/LocalForm/View';
import localFormSVG from '@plone/volto/icons/form.svg';

/// Listing
import ThreeColumnTemplate from './components/Blocks/Listing/ThreeColumnTemplate';
import ProfilesTemplate from './components/Blocks/Listing/ProfilesTemplate';
import SimpleGridTemplate from './components/Blocks/Listing/SimpleGridTemplate';
import LegislaturaGridItem from './components/Blocks/Listing/LegislaturaGridItem';
import MesaDiretoraGridItem from './components/Blocks/Listing/MesaDiretoraGridItem';
import ComissaoGridItem from './components/Blocks/Listing/ComissaoGridItem';
import VereadorGridItem from './components/Blocks/Listing/VereadorGridItem';
import ContratacoesTemplate from './components/Blocks/Listing/ContratacoesTemplate';
import DocumentosTemplate from './components/Blocks/Listing/DocumentosTemplate';
import ContratosTemplate from './components/Blocks/Listing/ContratosTemplate';
import RelatorioGestaoFiscalTemplate from './components/Blocks/Listing/RelatorioGestaoFiscalTemplate';
import RelatoriosAtividadesTemplate from './components/Blocks/Listing/RelatoriosAtividadesTemplate';

// reducers
import { acessibilidade } from './reducers/acessibilidade';
import { financasData } from './reducers/financasData';
import { localFormData } from './reducers/localFormData';
import { vereadorData } from './reducers/vereadorData';

const BG_COLORS = [
  { name: 'transparent', label: 'Transparent' },
  { name: 'grey', label: 'Cinza' },
  { name: 'blue', label: 'Azul' },
];

const applyConfig = (config) => {
  config.settings = {
    ...config.settings,
    isMultilingual: false,
    supportedLanguages: ['pt-br'],
    defaultLanguage: 'pt-br',
    contextualVocabularies: [
      'plonegovbr.vocabulary.pncp.fornecedores_contexto',
    ],
  };

  if (config.widgets?.widget) {
    config.widgets.widget.cep = CEPWidget;
    config.widgets.widget.cpf = CPFWidget;
    config.widgets.widget.cnpj = CNPJWidget;
    config.widgets.widget.json = JSONWidget;
    config.widgets.widget.telefone = TelefoneWidget;
  }

  if (config.widgets?.factory) {
    config.widgets.factory.CEP = CEPWidget;
    config.widgets.factory.CPF = CPFWidget;
    config.widgets.factory.CNPJ = CNPJWidget;
    config.widgets.factory.Telefone = TelefoneWidget;
  }

  // Listing Variations
  config.blocks.blocksConfig.listing.variations = [
    ...config.blocks.blocksConfig.listing.variations,
    {
      id: 'simple-grid',
      title: 'Simple Grid',
      template: SimpleGridTemplate,
    },
    {
      id: 'three-columns',
      title: 'Três Colunas',
      template: ThreeColumnTemplate,
    },
    {
      id: 'profiles',
      title: 'Perfis',
      template: ProfilesTemplate,
    },
    {
      id: 'contratacoes',
      title: 'Contratações',
      template: ContratacoesTemplate,
    },
    {
      id: 'documentos',
      title: 'Documentos',
      template: DocumentosTemplate,
    },
    {
      id: 'contratos',
      title: 'Contratos',
      template: ContratosTemplate,
    },
    {
      id: 'relatorios-atividades',
      title: 'Relatórios de Atividades',
      template: RelatoriosAtividadesTemplate,
    },
    {
      id: 'relatorios-gestao-fiscal',
      title: 'Relatórios de Gestão Fiscal',
      template: RelatorioGestaoFiscalTemplate,
    },
  ];

  config.registerComponent({
    name: 'GridListingItemTemplate',
    component: VereadorGridItem,
    dependencies: 'ParticipanteMesa',
  });

  config.registerComponent({
    name: 'GridListingItemTemplate',
    component: ComissaoGridItem,
    dependencies: 'Comissao',
  });

  config.registerComponent({
    name: 'GridListingItemTemplate',
    component: VereadorGridItem,
    dependencies: 'Vereador',
  });

  config.registerComponent({
    name: 'GridListingItemTemplate',
    component: LegislaturaGridItem,
    dependencies: 'Legislatura',
  });
  config.registerComponent({
    name: 'GridListingItemTemplate',
    component: MesaDiretoraGridItem,
    dependencies: 'Mesa Diretora',
  });

  // Default blocks
  config.blocks.initialBlocks = {
    ...config.blocks.initialBlocks,
    Area: ['title', 'description'],
    DemonstracaoContabil: ['title', 'description'],
    Document: ['title', 'description'],
    Duodecimo: ['title', 'description'],
    InformacaoCeap: ['title', 'description'],
    InformacaoEmprel: ['title', 'description'],
    Materias: ['title', 'description'],
    Normas: ['title', 'description'],
    PastaPesquisa: ['title', 'description'],
  };

  // Views
  config.views.contentTypesViews = {
    ...config.views.contentTypesViews,
    Area: AreaView,
    Colaborador: ColaboradorView,
    Colaboradores: ColaboradoresView,
    Contratacao: ContratacaoView,
    Contrato: ContratoView,
    Documento: DocumentoView,
    DemonstracaoContabil: DemonstracaoContabilView,
    Diaria: DiariaView,
    Duodecimo: DuodecimoView,
    Fornecedor: FornecedorView,
    ESIC: ESICView,
    InformacaoCeap: InformacaoCeapView,
    InformacaoEmprel: InformacaoEmprelView,
    InformacaoSigilosa: InformacaoSigilosaView,
    Obra: ObraView,
    Obras: PastaPesquisaView,
    Pagamento: PagamentoView,
    Pagamentos: PastaPesquisaView,
    PastaPesquisa: PastaPesquisaView,
    Comissao: ComissaoView,
    Legislatura: LegislaturaView,
    Mesa: MesaDiretoraView,
    Norma: NormaView,
    Materia: MateriaView,
    ParticipanteMesa: VereadorView,
    SessaoPlenaria: SessaoPlenariaView,
    Vereador: VereadorView,
    JulgamentoContas: JulgamentoContasView,
    PrestacaoContas: PrestacaoContasView,
    AdesaoAta: AdesaoAtaView,
    Convenio: ConvenioView,
    ConvenioRecursos: ConvenioView,
    ConcursoPublico: ConcursoPublicoView,
    relatorio_de_gestao_fiscal: GestaoFiscalView,
  };

  //Reducers
  config.addonReducers = {
    ...config.addonReducers,
    acessibilidade,
    financasData,
    localFormData,
    vereadorData,
  };

  // Grupo de blocos
  config.blocks.groupBlocksOrder = [
    ...config.blocks.groupBlocksOrder,
    { id: 'camara', title: 'Câmara' },
  ];

  // Blocks
  config.blocks.blocksConfig.exportDataBlock = {
    id: 'exportDataBlock',
    title: 'Exportar Dados',
    group: 'camara',
    icon: exportSVG,
    view: ExportDataView,
    restricted: ({ properties }) =>
      !properties.portal_type === 'InfoLegislativa',
    mostUsed: false,
    sidebarTab: 0,
    blockHasOwnFocusManagement: false,
  };

  config.blocks.blocksConfig.areasBlock = {
    id: 'areasBlock',
    title: 'Unidades Administrativas',
    group: 'camara',
    icon: areasSVG,
    view: AreasBlockView,
    edit: AreasBlockEdit,
    restricted: ({ properties }) => !properties.portal_type === 'Area',
    mostUsed: false,
    sidebarTab: 0,
    blockHasOwnFocusManagement: false,
  };

  config.blocks.blocksConfig.colaboradoresBlock = {
    id: 'colaboradoresBlock',
    title: 'Equipe',
    group: 'camara',
    icon: colaboradoresSVG,
    view: ColaboradoresBlockView,
    edit: ColaboradoresBlockEdit,
    restricted: ({ properties }) => !properties.portal_type === 'Area',
    mostUsed: false,
    sidebarTab: 1,
    blockHasOwnFocusManagement: false,
  };

  config.blocks.blocksConfig.localizacaoBlock = {
    id: 'localizacaoBlock',
    title: 'Localização',
    group: 'camara',
    icon: locationSvg,
    view: LocalizacaoBlockView,
    edit: LocalizacaoBlockEdit,
    restricted: ({ properties }) => !properties.hasOwnProperty('endereco'),
    mostUsed: false,
    sidebarTab: 0,
    blockHasOwnFocusManagement: false,
  };

  config.blocks.blocksConfig.vereadorBlock = {
    id: 'vereadorBlock',
    title: 'Vereador',
    group: 'camara',
    icon: vereadorSVG,
    view: VereadorBlockView,
    edit: VereadorBlockEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    blockHasOwnFocusManagement: false,
  };

  config.blocks.blocksConfig.contatoBlock = {
    id: 'contatoBlock',
    title: 'Contato',
    group: 'camara',
    icon: contatoSVG,
    view: ContatoBlockView,
    edit: ContatoBlockEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    blockHasOwnFocusManagement: false,
  };

  config.blocks.blocksConfig.diariasBlock = {
    id: 'diariasBlock',
    title: 'Relatório de diárias',
    group: 'camara',
    icon: diariasSVG,
    view: DiariasView,
    edit: DiariasEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    blockHasOwnFocusManagement: false,
  };
  config.blocks.blocksConfig.fiscaisBlock = {
    id: 'fiscaisBlock',
    title: 'Fiscais de Contrato',
    group: 'camara',
    icon: fiscaisSVG,
    view: FiscaisView,
    edit: FiscaisEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    blockHasOwnFocusManagement: false,
  };

  config.blocks.blocksConfig.localFormBlock = {
    id: 'localFormBlock',
    title: 'Consulta Dados Externos',
    group: 'camara',
    icon: localFormSVG,
    view: LocalFormView,
    edit: LocalFormEdit,
    restricted: false,
    mostUsed: false,
    sidebarTab: 1,
    blockHasOwnFocusManagement: false,
  };

  // Adiciona cores aos blocos
  [
    '__button',
    'accordion',
    'contatoBlock',
    'localizacaoBlock',
    'gridBlock',
    'heading',
    'listing',
    'maps',
    'search',
    'slate',
    'slateTable',
    'slider',
    'teaser',
    'vereadorBlock',
    'video',
  ].forEach((blockId) => {
    const blocksConfig = config.blocks.blocksConfig;
    const block = blocksConfig[blockId];
    if (block !== undefined) {
      blocksConfig[blockId] = {
        ...blocksConfig[blockId],
        colors: BG_COLORS,
      };
    }
  });

  // Add block to grid
  // Array of local blocks ids
  const localBlocks = [
    '__button',
    'contatoBlock',
    'contatoBlock',
    'localizacaoBlock',
    'maps',
    'slate',
    'vereadorBlock',
    'video',
  ];

  // Add Blocks to gridBlock
  // It's important to maintain the chain, and do not introduce pass by reference in
  // the internal `blocksConfig` object, so we clone the object to avoid this.
  ['gridBlock', 'accordion'].forEach((blockId) => {
    const block = config.blocks.blocksConfig[blockId];
    if (
      block !== undefined &&
      block.allowedBlocks !== undefined &&
      block.blocksConfig !== undefined
    ) {
      block.allowedBlocks = [...block.allowedBlocks, ...localBlocks];
      localBlocks.forEach((blockId) => {
        block.blocksConfig[blockId] = cloneDeep(
          config.blocks.blocksConfig[blockId],
        );
      });
    }
  });
  // Default Blocks
  // Configure default blocks for InformacaoLegislativa
  config.blocks.initialBlocks = {
    ...config.blocks.initialBlocks,
    InformacaoLegislativa: [
      { '@type': 'title', fixed: true },
      { '@type': 'description', fixed: true },
      { '@type': 'listing', fixed: true, variation: 'grid' },
    ],
    Area: [
      { '@type': 'title', fixed: true },
      { '@type': 'description', fixed: true },
      { '@type': 'slate', fixed: true },
      { '@type': 'colaboradoresBlock', fixed: false },
      { '@type': 'areasBlock', fixed: false },
    ],
  };

  config.settings.appExtras = [
    ...config.settings.appExtras,
    {
      match: '',
      component: Libras,
    },
  ];

  return config;
};

export default applyConfig;
