import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { processColumns } from '../../../helpers/columns';
import ExternalDataForm from '../../ExternalDataForm/ExternalDataForm';
import ExternalDataResults from '../../ExternalDataResults/ExternalDataResults';

const LocalFormView = (props) => {
  const { className, content } = props;
  const { view_config } = content;
  const [localData, setLocalData] = useState();
  const [showForm, setShowForm] = useState(true);
  const display_form = true;

  const displayForm = () => {
    setShowForm(true);
    setLocalData({});
  };

  const setLocalFormData = (data) => {
    if (data?.data?.items) {
      setLocalData(data);
      setShowForm(false);
    }
  };
  const pagination = view_config?.display?.pagination;
  const paginationPerPage = pagination?.paginationPerPage;
  const columns = processColumns(view_config?.display?.columns);
  const expandRows = view_config?.display?.expandRows;
  const expandRowsType = view_config?.display?.expandRowsType;
  const expandRowsHeader = view_config?.display?.expandRowsHeader;
  const metadata = localData?.metadata;

  return (
    <div className={`block localForm ${className}`}>
      {!showForm && metadata && (
        <ExternalDataResults
          data={localData.data}
          metadata={metadata}
          columns={columns}
          showForm={displayForm}
          display_form={display_form}
          expandRows={expandRows}
          expandRowsType={expandRowsType}
          expandRowsHeader={expandRowsHeader}
          paginationPerPage={paginationPerPage}
        />
      )}
      {showForm && (
        <ExternalDataForm
          {...props}
          title={'Filtro'}
          setLocalFormData={setLocalFormData}
        />
      )}
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
LocalFormView.propTypes = {
  title: PropTypes.string,
};

/**
 * Default properties.
 * @property {Object} defaultProps Default properties.
 * @static
 */
LocalFormView.defaultProps = {
  title: 'Hello World!',
};

export default LocalFormView;
