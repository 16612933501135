import React from 'react';
import PropTypes from 'prop-types';
import { Container } from '@plone/components';
import LocalDate from '../LocalDate/LocalDate';
import { flattenToAppURL } from '@plone/volto/helpers';
import PDFBlockView from '@eeacms/volto-pdf-block/components/manage/PDFViewer/BlockView';
import { TableRow, TableCell, TableBody, Table } from 'semantic-ui-react';
import FileType from '@kitconcept/volto-light-theme/helpers/Filetype';

const FileView = ({ file }) => {
  const contentType = file['content-type'];
  const downloadUrl = file?.download && flattenToAppURL(file.download);
  return (
    <>
      {contentType === 'application/pdf' && (
        <PDFBlockView data={{ url: downloadUrl }} />
      )}
      {downloadUrl && (
        <p>
          <a href={downloadUrl}>{file.filename}</a>{' '}
          <span>
            ({FileType(file['content-type'])}/{' '}
            {file?.size < 1000000
              ? Math.round(file.size / 1000)
              : Math.round(file.size / 1000000)}
            {file?.size < 1000000 ? 'KB' : 'MB'})
          </span>
        </p>
      )}
    </>
  );
};

const GestaoFiscalView = ({ content }) => {
  return (
    <Container
      narrow
      id="page-document"
      className="view-wrapper gestaofiscal-view"
    >
      <h1 className="documentFirstHeading">
        {content.title}
        {content.subtitle && ` - ${content.subtitle}`}
      </h1>
      {content.description && (
        <p className="documentDescription">{content.description}</p>
      )}
      {content.arquivo_pdf && (
        <>
          <h2 className={'headline'}>Arquivo</h2>
          <FileView file={content.arquivo_pdf} />
        </>
      )}
      {content.arquivo_pdf_publicacao && (
        <>
          <h2 className={'headline'}>Publicação</h2>
          <Table className={'details'}>
            <TableBody>
              {content.veiculo && (
                <TableRow>
                  <TableCell className={'label'}>
                    Veículo da publicação
                  </TableCell>
                  <TableCell className={'value'}>
                    {content.veiculo.title}
                  </TableCell>
                </TableRow>
              )}
              {content.data_de_publicacao && (
                <TableRow>
                  <TableCell className={'label'}>Data da publicação</TableCell>
                  <TableCell className={'value'}>
                    <LocalDate date={content.data_de_publicacao} />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <FileView file={content.arquivo_pdf} />
        </>
      )}
    </Container>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
GestaoFiscalView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    ano: PropTypes.number,
    quad: PropTypes.shape({
      token: PropTypes.string,
      title: PropTypes.string,
    }),
    arquivo_pdf: PropTypes.shape({
      download: PropTypes.string,
      filename: PropTypes.string,
    }),
    arquivo_pdf_publicacao: PropTypes.shape({
      download: PropTypes.string,
      filename: PropTypes.string,
    }),
  }).isRequired,
};

export default GestaoFiscalView;
