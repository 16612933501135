import React from 'react';
import DataTable from 'react-data-table-component';
import ExportRow from '../ExportRow/ExportRow';
import { processColumns } from '../../helpers/columns';

const baseColumns = {
  main: [
    {
      id: 'title',
      style: 'text',
      format: 'link',
      title: 'Ano',
      sortable: true,
    },
    {
      id: 'description',
      style: 'text wrap',
      title: 'Sumário',
      sortable: false,
    },
    {
      id: 'file',
      style: 'text wrap',
      format: 'link_file',
      title: 'Arquivo',
      sortable: false,
    },
  ],
};

const RelatoriosAtividades = ({ url, params, documentos, allowExport }) => {
  const columns = processColumns(baseColumns);
  return (
    <>
      <DataTable
        className={'contratos-items'}
        columns={columns['main']}
        data={documentos}
        dense
        striped
      />
      {allowExport && (
        <ExportRow
          url={url}
          params={params}
          endpoint={'@export-generico/atividades'}
        />
      )}
    </>
  );
};

export default RelatoriosAtividades;
