/**
 * InformacaoSigilosaView view component.
 * @module components/View/Licitacao/InformacaoSigilosaView
 */
import React from 'react';
import { Container } from '@plone/components';
import { TableRow, TableCell, TableBody, Table } from 'semantic-ui-react';
import FileViewer from '../FileViewer/FileViewer';
import Estado from '../Estado/Estado';
import LocalDate from '../LocalDate/LocalDate';

const DadosBasicos = ({ content }) => {
  return (
    <Table className={'details'}>
      <TableBody>
        <TableRow>
          <TableCell className={'label'}>Dispositivo Legal</TableCell>
          <TableCell className={'value'}>{content.dispositivo_legal}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell className={'label'}>Autoridade Classificadora</TableCell>
          <TableCell className={'value'}>{content.autoridade}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell className={'label'}>Grau de sigilo</TableCell>
          <TableCell className={'value'}>
            {content.grau?.title ? <span>{content.grau.title}</span> : ' - '}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell className={'label'}>Data da produção</TableCell>
          <TableCell className={'value'}>
            {content.data_producao && (
              <LocalDate date={content.data_producao} />
            )}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell className={'label'}>Data da classificação</TableCell>
          <TableCell className={'value'}>
            {content.start && <LocalDate date={content.start} />}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell className={'label'}>Prazo da classificação</TableCell>
          <TableCell className={'value'}>
            {content.end && <LocalDate date={content.end} />}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

/**
 * InformacaoSigilosaView view component class.
 * @function InformacaoSigilosaView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const InformacaoSigilosaView = ({ content }) => {
  const file = content.file;
  return (
    <Container narrow id="page-document" className="informacaosigilosa-view">
      <div className={'header-estado'}>
        <h1 className="documentFirstHeading">{content.title}</h1>
        <Estado review_state={content.status} />
      </div>
      <h2 className={'headline'}>Categoria</h2>
      <p>{content.description}</p>
      <h2 className={'headline'}>Detalhes</h2>
      <DadosBasicos content={content} />
      {file && <FileViewer file={file} header={'Contrato'} />}
    </Container>
  );
};

export default InformacaoSigilosaView;
