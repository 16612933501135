import React from 'react';
import { Image } from '@plone/volto/components';
import DefaultImageSVG from '@plone/volto/components/manage/Blocks/Listing/default-image.svg';

const VereadorGridItem = ({ item }) => {
  const description = item?.partido
    ? item?.partido?.[0].title
    : item.description;
  return (
    <div className="card-container profile">
      <div className="item-image">
        {item.image && item.image.length > 0 ? (
          <Image src={item.image[0].download} alt="" className="item-image" />
        ) : (
          <img src={DefaultImageSVG} alt="" className="item-image" />
        )}
      </div>
      <div className="item">
        <div className="content">
          {item?.cargo && <div className="headline">{item.cargo}</div>}

          <h2>{item?.title}</h2>
          <p>{description}</p>
        </div>
      </div>
    </div>
  );
};

export default VereadorGridItem;
