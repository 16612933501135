import { defineMessages } from 'react-intl';

const messages = defineMessages({
  vereador: {
    id: 'Vereador',
    defaultMessage: 'Vereador',
  },
  cod_vereador: {
    id: 'Vereador',
    defaultMessage: 'Vereador',
  },
});

export const vereadorSchema = (props) => {
  return {
    title: props.intl.formatMessage(messages.vereador),
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['cod_vereador'],
      },
    ],
    properties: {
      cod_vereador: {
        title: props.intl.formatMessage(messages.cod_vereador),
        widget: 'select',
        vocabulary: { '@id': 'transp_cmr.vocabulary.vereadores' },
      },
    },
    required: ['cod_vereador'],
  };
};
