/**
 * Vereador view component.
 * @module components/Views/VereadorView
 */
import React from 'react';
import PropTypes from 'prop-types';
import { TabPane, Tab } from 'semantic-ui-react';
import {
  TableRow,
  TableHeaderCell,
  TableHeader,
  TableCell,
  TableBody,
  Table,
} from 'semantic-ui-react';
import { Container } from '@plone/components';
import { Image, UniversalLink } from '@plone/volto/components';
import LocalDate from '../../LocalDate/LocalDate';

const Details = ({ content }) => {
  return (
    <Container className={'details'}>
      <Table className={'details'}>
        <TableBody>
          {content.filiacoes && content.filiacoes.length > 0 && (
            <TableRow>
              <TableCell className={'label'}>Partido</TableCell>
              <TableCell className={'value'}>
                {content.filiacoes[0]?.title}
              </TableCell>
            </TableRow>
          )}
          {content.birthday && (
            <TableRow>
              <TableCell className={'label'}>Nascimento</TableCell>
              <TableCell className={'value'}>{content.birthday}</TableCell>
            </TableRow>
          )}
          {content.telefone_gabinete && (
            <TableRow>
              <TableCell className={'label'}>Telefone</TableCell>
              <TableCell className={'value'}>
                {content.telefone_gabinete}
              </TableCell>
            </TableRow>
          )}
          {content.email && (
            <TableRow>
              <TableCell className={'label'}>E-mail</TableCell>
              <TableCell className={'value'}>{content.email}</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Container>
  );
};

const Mandatos = ({ items }) => {
  return (
    <Table celled>
      <TableHeader>
        <TableRow>
          <TableHeaderCell>Legislatura</TableHeaderCell>
          <TableHeaderCell>Início</TableHeaderCell>
          <TableHeaderCell>Fim</TableHeaderCell>
          <TableHeaderCell>Natureza</TableHeaderCell>
          <TableHeaderCell>Votos</TableHeaderCell>
        </TableRow>
      </TableHeader>

      <TableBody>
        {items &&
          items.map((item) => (
            <TableRow>
              <TableCell>
                <UniversalLink item={item}>{item.id}</UniversalLink>
              </TableCell>
              <TableCell>
                <LocalDate date={item.start} />
              </TableCell>
              <TableCell>
                <LocalDate date={item.end} />
              </TableCell>
              <TableCell>{item.natureza}</TableCell>
              <TableCell>{item.votos}</TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
};

const Partidos = ({ items }) => {
  return (
    <Table celled>
      <TableHeader>
        <TableRow>
          <TableHeaderCell>Partido</TableHeaderCell>
          <TableHeaderCell>Filiação</TableHeaderCell>
          <TableHeaderCell>Desfiliação</TableHeaderCell>
        </TableRow>
      </TableHeader>

      <TableBody>
        {items &&
          items.map((item) => (
            <TableRow positive={item.filiacao_atual}>
              <TableCell>{item.title}</TableCell>
              <TableCell>
                {item.data_filiacao && <LocalDate date={item.data_filiacao} />}
              </TableCell>
              <TableCell>
                {item.data_desfiliacao && (
                  <LocalDate date={item.data_desfiliacao} />
                )}
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
};

const Mesas = ({ items }) => {
  return (
    <Table celled>
      <TableHeader>
        <TableRow>
          <TableHeaderCell>Título</TableHeaderCell>
          <TableHeaderCell>Início</TableHeaderCell>
          <TableHeaderCell>Fim</TableHeaderCell>
        </TableRow>
      </TableHeader>

      <TableBody>
        {items &&
          items.map((item) => (
            <TableRow>
              <TableCell>
                <UniversalLink item={item}>{item.title}</UniversalLink>
              </TableCell>
              <TableCell>
                <LocalDate date={item.start} />
              </TableCell>
              <TableCell>
                <LocalDate date={item.end} />
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
};

const Comissoes = ({ items }) => {
  return (
    <Table celled>
      <TableHeader>
        <TableRow>
          <TableHeaderCell>Comissão</TableHeaderCell>
          <TableHeaderCell>Título</TableHeaderCell>
          <TableHeaderCell>Início</TableHeaderCell>
          <TableHeaderCell>Fim</TableHeaderCell>
        </TableRow>
      </TableHeader>

      <TableBody>
        {items &&
          items.map((item) => (
            <TableRow>
              <TableCell>
                <UniversalLink item={item}>{item.comissao}</UniversalLink>
              </TableCell>
              <TableCell>{item.title}</TableCell>
              <TableCell>
                <LocalDate date={item.start} />
              </TableCell>
              <TableCell>
                <LocalDate date={item.end} />
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
};

/**
 * Vereador view component class.
 * @function VereadorView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const VereadorView = (props) => {
  const { content } = props;
  const { title, biografia, image, mesas, comissoes } = content;
  const img_src = image ? image[0].download : null;

  const panes = [
    {
      menuItem: 'Biografia',
      render: () => (
        <TabPane>
          <div
            className={'biografia'}
            dangerouslySetInnerHTML={{ __html: biografia }}
          />
        </TabPane>
      ),
    },
    {
      menuItem: 'Partidos',
      render: () => (
        <TabPane>
          <Partidos items={content.filiacoes} />
        </TabPane>
      ),
    },
    {
      menuItem: 'Mandatos',
      render: () => (
        <TabPane>
          <Mandatos items={content.mandatos} />
        </TabPane>
      ),
    },
  ];
  if (mesas) {
    panes.push({
      menuItem: 'Comissão Executiva',
      render: () => (
        <TabPane>
          <Mesas items={mesas} />
        </TabPane>
      ),
    });
  }
  if (comissoes) {
    panes.push({
      menuItem: 'Comissões',
      render: () => (
        <TabPane>
          <Comissoes items={comissoes} />
        </TabPane>
      ),
    });
  }

  return (
    <Container narrow id="page-document" className="view-wrapper vereador-view">
      <h1 className="documentFirstHeading">{title}</h1>
      <div id={'card'}>
        <div className={'image'}>
          {img_src && <Image src={img_src} className={'portrait'} />}
        </div>

        <div className={'data'}>
          <Details content={content} />
        </div>
      </div>

      <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
    </Container>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
VereadorView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
};

export default VereadorView;
