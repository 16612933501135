import React from 'react';

const ComissaoGridItem = ({ item }) => {
  const head_title = item.head_title ? item.head_title : item.tipo;
  return (
    <div className={`card-container comissao`}>
      <div className="item">
        <div className="content">
          {head_title && <div className="headline">{head_title}</div>}
          <h2>{item?.description}</h2>
          <p>{item?.title}</p>
        </div>
      </div>
    </div>
  );
};

export default ComissaoGridItem;
