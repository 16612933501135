/**
 * Fornecedor view component.
 * @module components/Views/FornecedorView
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Container } from '@plone/components';
import { TabPane, Tab } from 'semantic-ui-react';
import { TableRow, TableCell, TableBody, Table } from 'semantic-ui-react';
import Contratacoes from '../Contratacoes/Contratacoes';
import Estado from '../Estado/Estado';
import { CNPJWidgetDisplay } from '../Widgets/CNPJWidget';
import { CEPWidgetDisplay } from '../Widgets/CEPWidget';
import { TelefoneWidgetDisplay } from '../Widgets/TelefoneWidget';

const DadosBasicos = ({ title, cnpj, cpf }) => {
  return (
    <Table className={'details'}>
      <TableBody>
        <TableRow>
          <TableCell className={'label'}>Razão Social</TableCell>
          <TableCell className={'value'}>{title}</TableCell>
        </TableRow>
        {cnpj && (
          <TableRow>
            <TableCell className={'label'}>CNPJ</TableCell>
            <TableCell className={'value'}>
              <CNPJWidgetDisplay value={cnpj} />
            </TableCell>
          </TableRow>
        )}
        {cpf && (
          <TableRow>
            <TableCell className={'label'}>CPF</TableCell>
            <TableCell className={'value'}>
              <CNPJWidgetDisplay value={cpf} />
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

const DadosContato = ({ email, telefone, fax }) => {
  return (
    <Table className={'details'}>
      <TableBody>
        <TableRow>
          <TableCell className={'label'}>E-mail</TableCell>
          <TableCell className={'value'}>{email ? email : '-'}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell className={'label'}>Telefone</TableCell>
          <TableCell className={'value'}>
            {telefone ? <TelefoneWidgetDisplay value={telefone} /> : '-'}
          </TableCell>
        </TableRow>
        {fax && (
          <TableRow>
            <TableCell className={'label'}>Fax</TableCell>
            <TableCell className={'value'}>
              <TelefoneWidgetDisplay value={fax} />
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

const DadosEndereco = ({ endereco, cep, cidade, estado }) => {
  return (
    <Table className={'details'}>
      <TableBody>
        <TableRow>
          <TableCell className={'label'}>Endereço</TableCell>
          <TableCell className={'value'}>{endereco ? endereco : '-'}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell className={'label'}>Cidade / Estado</TableCell>
          <TableCell className={'value'}>
            {cidade ? cidade : '-'} / {estado.title ? estado.title : '-'}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell className={'label'}>CEP</TableCell>
          <TableCell className={'value'}>
            {cep ? <CEPWidgetDisplay value={cep} /> : '-'}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

const DadosContracoes = ({ contratacoes }) => {
  return <Contratacoes contratacoes={contratacoes} />;
};

const FornecedorView = (props) => {
  const { content } = props;
  const panes = [
    {
      menuItem: 'Informações',
      render: () => (
        <TabPane>
          <DadosBasicos
            title={content.title}
            cnpj={content.cnpj}
            cpf={content.cpf}
          />
        </TabPane>
      ),
    },
  ];
  if (content.email || content.telefone) {
    panes.push({
      menuItem: 'Contato',
      render: () => (
        <TabPane>
          <DadosContato
            email={content.email}
            telefone={content.telefone}
            fax={content.fax}
          />
        </TabPane>
      ),
    });
  }
  if (content.endereco) {
    panes.push({
      menuItem: 'Endereço',
      render: () => (
        <TabPane>
          <DadosEndereco
            endereco={content.endereco}
            cidade={content.cidade}
            estado={content.estado}
            cep={content.cep}
          />
        </TabPane>
      ),
    });
  }
  if (content.contratacoes) {
    panes.push({
      menuItem: 'Processos',
      render: () => (
        <TabPane>
          <DadosContracoes contratacoes={content.contratacoes} />
        </TabPane>
      ),
    });
  }
  return (
    <Container id="page-document" className="view-wrapper fornecedor-view">
      <Container className={'fornecedorHeader'}>
        <Container className={'fornecedorDados'}>
          <div className={'nome'}>
            <h1 className="documentFirstHeading">{content.title}</h1>
            <Estado review_state={content.review_state} />
          </div>
          {content.description && (
            <p className="documentDescription">{content.description}</p>
          )}
        </Container>
      </Container>
      <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
    </Container>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
FornecedorView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
};

export default FornecedorView;
