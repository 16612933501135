/**
 * Obra view component.
 * @module components/Views/ObraView
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Container } from '@plone/components';
import { TableRow, TableCell, TableBody, Table } from 'semantic-ui-react';
import { DownloadLink } from '../DownloadActions/DownloadActions';
import LocalDate from '../LocalDate/LocalDate';

const ObraView = (props) => {
  const { content } = props;
  const file_href = content.file && content.file.download;
  const file_acompanhamento_href =
    content.file_acompanhamento && content.file_acompanhamento.download;
  return (
    <Container narrow id="page-document" className="view-wrapper obra-view">
      <h1 className="documentFirstHeading">{content.title}</h1>
      <span>{content.status.title}</span>
      <Table className={'details'}>
        <TableBody>
          <TableRow>
            <TableCell className={'label'}>Data de início</TableCell>
            <TableCell className={'value'}>
              <LocalDate date={content.start} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={'label'}>Data prevista de término</TableCell>
            <TableCell className={'value'}>
              {content.data_prevista ? (
                <LocalDate date={content.data_prevista} />
              ) : (
                <span>{'-'}</span>
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={'label'}>Data de término</TableCell>
            <TableCell className={'value'}>
              {content.end ? (
                <LocalDate date={content.end} />
              ) : (
                <span>{'-'}</span>
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={'label'}>Percentual executado</TableCell>
            <TableCell className={'value'}>
              {content.percentual ? (
                <span>{content.percentual}%</span>
              ) : (
                <span>{'0%'}</span>
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={'label'}>Planejado x Executado</TableCell>
            <TableCell className={'value'}>
              {content.planejado_executado ? (
                <span>{content.planejado_executado.title}</span>
              ) : (
                <span>{'0%'}</span>
              )}
            </TableCell>
          </TableRow>
          {file_href && (
            <TableRow>
              <TableCell className={'label'}>Planilha original</TableCell>
              <TableCell className={'value'}>
                <span className={'link'}>
                  <DownloadLink
                    format={'pdf'}
                    title={'Download'}
                    href={file_href}
                  />
                </span>
              </TableCell>
            </TableRow>
          )}
          {file_acompanhamento_href && (
            <TableRow>
              <TableCell className={'label'}>Planilha acompanhamento</TableCell>
              <TableCell className={'value'}>
                <span className={'link'}>
                  <DownloadLink
                    format={'pdf'}
                    title={'Download'}
                    href={file_acompanhamento_href}
                  />
                </span>
              </TableCell>
            </TableRow>
          )}
          {content.motivo_paralizacao && (
            <TableRow>
              <TableCell className={'label'}>Motivo da paralização</TableCell>
              <TableCell className={'value'}>
                <span>{content.motivo_paralizacao}</span>
              </TableCell>
            </TableRow>
          )}
          {content.responsavel_inexecucao && (
            <TableRow>
              <TableCell className={'label'}>
                Responsável pela inexecução
              </TableCell>
              <TableCell className={'value'}>
                <span>{content.responsavel_inexecucao}</span>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Container>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
ObraView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
  }).isRequired,
};

export default ObraView;
