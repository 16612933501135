/**
 * JSONWidget component.
 * @module components/Widgets/JSONWidget
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Label, TextArea } from 'semantic-ui-react';
import { FormFieldWrapper } from '@plone/volto/components';

import { injectIntl } from 'react-intl';

const JSONWidget = (props) => {
  const { id, value, onChange, placeholder, isDisabled } = props;
  const [textValue, setTextValue] = useState('');
  const [jsonError, setJsonError] = useState('');
  useEffect(() => {
    setTextValue(JSON.stringify(value, null, 2));
  }, [value, setTextValue]);

  const onhandleChange = (id, value) => {
    try {
      const obj = JSON.parse(value);
      onChange(id, obj);
    } catch (e) {
      setJsonError('Invalid JSON.');
    }
  };

  return (
    <FormFieldWrapper {...props} className="json textarea">
      <TextArea
        id={`field-${id}`}
        name={id}
        value={textValue || ''}
        disabled={isDisabled}
        placeholder={placeholder}
        onChange={({ target }) =>
          onhandleChange(id, target.value === '' ? undefined : target.value)
        }
        rows={10}
      />
      {jsonError.length > 0 && (
        <Label key={jsonError} basic color="red" pointing>
          {jsonError}
        </Label>
      )}
    </FormFieldWrapper>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
JSONWidget.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.string,
  onChange: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  wrapped: PropTypes.bool,
  placeholder: PropTypes.string,
};

/**
 * Default properties.
 * @property {Object} defaultProps Default properties.
 * @static
 */
JSONWidget.defaultProps = {
  description: null,
  required: false,
  error: [],
  value: null,
  onChange: null,
  onEdit: null,
  onDelete: null,
};

export default injectIntl(JSONWidget);
