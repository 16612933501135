import { FormattedDate } from '@plone/volto/components';
import { isDate } from '../../helpers/dates';

const LocalDate = (props) => {
  // Force a date that would not fail timezone checks
  const localDate = isDate(props.date)
    ? `${props.date}T23:00:00+03:00`
    : props.date;
  return <FormattedDate {...props} date={localDate} />;
};

export default LocalDate;
