/**
 * SessaoPlenaria view component.
 * @module components/Views/SessaoPlenariaView
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Container } from '@plone/components';
import { TableRow, TableCell, TableBody, Table } from 'semantic-ui-react';
import { DownloadLink } from '../../DownloadActions/DownloadActions';
import PresencaSessao from '../../PresencaSessao/PresencaSessao';
import SessaoVotacoes from '../../SessaoVotacoes/SessaoVotacoes';

const Download = ({ file }) => {
  const format = 'pdf';
  const href = file?.download;
  const title = 'Baixar';
  return (
    <span className={`download link`}>
      {href && <DownloadLink format={format} title={title} href={href} />}
    </span>
  );
};

const DadosSessao = ({ item }) => {
  const { type, date, startTime, endTime, pauta, ata } = item;
  return (
    <Table className={'details'}>
      <TableBody>
        <TableRow>
          <TableCell className={'label'}>Tipo</TableCell>
          <TableCell className={'value'}>{type}</TableCell>
          <TableCell className={'label'}>Data</TableCell>
          <TableCell className={'value'}>{date ? date : '-'}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell className={'label'}>Início</TableCell>
          <TableCell className={'value'}>
            {startTime ? startTime : '-'}
          </TableCell>
          <TableCell className={'label'}>Término</TableCell>
          <TableCell className={'value'}>{endTime ? endTime : '-'}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell className={'label'}>Pauta</TableCell>
          <TableCell className={'value'}>
            {pauta && <Download file={pauta} />}
          </TableCell>
          <TableCell className={'label'}>Ata</TableCell>
          <TableCell className={'value'}>
            {ata && <Download file={ata} />}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

/**
 * Sessao Plenaria view component class.
 * @function SessaoPlenariaView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const SessaoPlenariaView = (props) => {
  const { content } = props;
  const { title, description } = content;

  return (
    <Container narrow id="page-document" className="view-wrapper sessao-view">
      <h1 className="documentFirstHeading">{title}</h1>
      <p className={'description'}>{description}</p>
      <DadosSessao item={content} />
      <PresencaSessao content={content.presenca} />
      <SessaoVotacoes content={content.votacao} />
    </Container>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
SessaoPlenariaView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
};

export default SessaoPlenariaView;
