/**
 * DownloadActions view component.
 * @module components/DownloadActions/DownloadActions
 */
import React from 'react';
import { Icon, UniversalLink } from '@plone/volto/components';
import iconCSV from '../../icons/file-csv.svg';
import iconExcel from '../../icons/file-excel.svg';
import iconPDF from '../../icons/file-pdf.svg';

const formats = {
  csv: iconCSV,
  excel: iconExcel,
  pdf: iconPDF,
};

export const DownloadLink = ({ format, title, href }) => {
  return (
    <UniversalLink
      href={href}
      title={title}
      download={true}
      openLinkInNewTab={true}
    >
      <Icon name={formats[format]} size={'24px'} title={title} />
    </UniversalLink>
  );
};

const DownloadActions = ({ actions }) => {
  return (
    <div className={'actions downloads'}>
      {actions.map((item, idx) => (
        <DownloadLink format={item.format} href={item.href} key={idx} />
      ))}
    </div>
  );
};

export default DownloadActions;
