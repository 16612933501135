import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { processColumns } from '../../../helpers/columns';
import ExternalDataForm from '../../ExternalDataForm/ExternalDataForm';
import ExternalDataResults from '../../ExternalDataResults/ExternalDataResults';

const view_config = {
  form: {
    formSchema: {
      properties: {
        Description: {
          type: 'string',
          title: 'Objeto',
          description: 'Objeto do contrato',
        },
        colaborador: {
          type: 'string',
          title: 'Fiscal',
          description: 'Nome do fiscal do contrato',
        },
        start_min: {
          title: 'Início do contrato (Data inicial)',
          type: 'string',
          format: 'date',
          description: 'Contratos com início de vigência a partir desta data',
        },
        start_max: {
          title: 'Início do contrato (Data final)',
          type: 'string',
          format: 'date',
          description: 'Contratos com início de vigência até esta data',
        },
        end_min: {
          title: 'Término do contrato (Data inicial)',
          type: 'string',
          format: 'date',
          description: 'Contratos com término de vigência a partir desta data',
        },
        end_max: {
          title: 'Término do contrato (Data final)',
          type: 'string',
          format: 'date',
          description: 'Contratos com término de vigência até esta data',
        },
      },
      required: [],
      type: 'object',
    },
    initialData: {},
    uiSchema: {
      semantic: {
        errorOptions: {
          pointing: 'above',
          size: 'small',
        },
        fluid: true,
        inverted: false,
        widths: 'equal',
      },
      'ui:order': [
        'Description',
        'colaborador',
        'start_min',
        'start_max',
        'end_min',
        'end_max',
      ],
      Description: {
        'ui:classNames': 'full',
      },
      colaborador: {
        'ui:classNames': 'full',
      },
      start_min: {
        'ui:classNames': 'half',
        'ui:options': {
          yearsRange: [2020, 2030],
          format: 'DMY',
        },
      },
      start_max: {
        'ui:classNames': 'half',
        'ui:options': {
          yearsRange: [2020, 2030],
          format: 'DMY',
        },
      },
      end_min: {
        'ui:classNames': 'half',
        'ui:options': {
          yearsRange: [2020, 2030],
          format: 'DMY',
        },
      },
      end_max: {
        'ui:classNames': 'half',
        'ui:options': {
          yearsRange: [2020, 2030],
          format: 'DMY',
        },
      },
      'ui:submitButtonOptions': {
        submitText: 'Filtrar',
      },
    },
  },
  display: {
    pagination: {
      paginationPerPage: 50,
    },
    columns: {
      main: [
        {
          id: 'contratacao_ano',
          style: 'text',
          title: 'Ano',
        },
        {
          id: 'title',
          style: 'text',
          format: 'link',
          title: 'Contrato',
        },
        {
          id: 'description',
          style: 'text wrap',
          title: 'Objeto',
        },
        {
          id: 'start',
          style: 'text wrap',
          center: true,
          format: 'date',
          title: 'Início',
        },
        {
          id: 'end',
          style: 'text wrap',
          center: true,
          format: 'date',
          title: 'Término',
        },
        {
          id: 'colaborador.title',
          style: 'text wrap',
          title: 'Fiscal',
          format: 'link',
        },
        {
          id: 'colaborador.cpf',
          style: 'text wrap',
          title: 'CPF',
        },
        {
          id: 'status',
          style: 'text wrap',
          format: 'reviewState',
          title: 'Situação',
        },
      ],
    },
  },
};

const FiscaisView = (props) => {
  const { className } = props;
  const [localData, setLocalData] = useState();
  const [showForm, setShowForm] = useState(true);
  const display_form = true;

  const displayForm = () => {
    setShowForm(true);
    setLocalData({});
  };

  const setFiscaisData = (data) => {
    if (data?.data?.items) {
      setLocalData(data);
      setShowForm(false);
    }
  };
  const pagination = view_config?.display?.pagination;
  const paginationPerPage = pagination?.paginationPerPage;
  const columns = processColumns(view_config?.display?.columns);
  const expandRows = view_config?.display?.expandRows;
  const expandRowsType = view_config?.display?.expandRowsType;
  const expandRowsHeader = view_config?.display?.expandRowsHeader;
  const metadata = localData?.metadata;

  return (
    <div className={`block localForm fiscais ${className}`}>
      {!showForm && metadata && (
        <ExternalDataResults
          data={localData.data}
          metadata={metadata}
          columns={columns}
          showForm={displayForm}
          display_form={display_form}
          expandRows={expandRows}
          expandRowsType={expandRowsType}
          expandRowsHeader={expandRowsHeader}
          paginationPerPage={paginationPerPage}
        />
      )}
      {showForm && (
        <ExternalDataForm
          {...props}
          title={'Filtro'}
          setLocalFormData={setFiscaisData}
          view_config={view_config}
          endpoint={'@formdata-fiscais'}
          displayCancel={true}
        />
      )}
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
FiscaisView.propTypes = {
  title: PropTypes.string,
};

export default FiscaisView;
