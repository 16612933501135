/**
 * PrestacaoContas view component.
 * @module components/Views/PrestacaoContasView
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Container } from '@plone/components';
import Estado from '../Estado/Estado';
import FileViewer from '../FileViewer/FileViewer';

/**
 * PrestacaoContas view component class.
 * @function PrestacaoContasView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const PrestacaoContasView = ({ content }) => {
  const file = content.file;
  const file_julgamento = content.file_julgamento;
  const file_resolucao = content.file_resolucao;
  return (
    <Container
      id="page-document"
      narrow
      className="view-wrapper prestacaocontas-view"
    >
      <div className={'header-estado'}>
        <h1 className="documentFirstHeading">{content.title}</h1>
        <Estado review_state={content.status} />
      </div>
      {content.description && (
        <p className="documentDescription">{content.description}</p>
      )}
      {file && <FileViewer file={file} header={'Arquivo'} />}
      {file_julgamento && (
        <FileViewer file={file_julgamento} header={'Resultado do julgamento'} />
      )}
      {file_resolucao && (
        <FileViewer file={file_resolucao} header={'Resolução TCE'} />
      )}
    </Container>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
PrestacaoContasView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    file: PropTypes.shape({
      download: PropTypes.string,
      filename: PropTypes.string,
    }),
  }).isRequired,
};

export default PrestacaoContasView;
