import React from 'react';
import { Container } from '@plone/components';
import ExportRow from '../../ExportRow/ExportRow';

const ExportDataView = (props) => {
  const { content } = props;
  return (
    <Container className={`block export`}>
      {content && <ExportRow url={content['@id']} />}
    </Container>
  );
};

export default ExportDataView;
