/**
 * PastaPesquisa view component.
 * @module components/Views/PastaPesquisaView
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { hasBlocksData, getBaseUrl } from '@plone/volto/helpers';
import { Container } from '@plone/components';
import RenderBlocks from '@plone/volto/components/theme/View/RenderBlocks';
import { processColumns } from '../../helpers/columns';
import ExternalDataForm from '../ExternalDataForm/ExternalDataForm';
import ExternalDataResults from '../ExternalDataResults/ExternalDataResults';

const PastaPesquisaView = (props) => {
  const { content, location } = props;
  const { title, description, display_form, view_config } = content;
  const path = getBaseUrl(location?.pathname || '');
  const [showForm, setShowForm] = useState(true);
  const [localData, setLocalData] = useState();

  const displayForm = () => {
    setShowForm(true);
    setLocalData({});
  };

  const setLocalFormData = (data) => {
    if (data?.data?.items) {
      setLocalData(data);
      setShowForm(false);
    }
  };
  const pagination = view_config?.display?.pagination;
  const paginationPerPage = pagination?.paginationPerPage;
  const columns = processColumns(view_config?.display?.columns);
  const expandRows = view_config?.display?.expandRows;
  const expandRowsType = view_config?.display?.expandRowsType;
  const metadata = localData?.metadata;
  return (
    <Container id="page-document" className="view-wrapper colaboradores-view">
      {hasBlocksData(content) ? (
        <RenderBlocks {...props} path={path} />
      ) : (
        <div className={'cabecalho'}>
          <h1 className="documentFirstHeading">{title}</h1>
          {description && <p className="documentDescription">{description}</p>}
        </div>
      )}
      {!showForm && metadata && (
        <ExternalDataResults
          data={localData.data}
          metadata={metadata}
          columns={columns}
          showForm={displayForm}
          display_form={display_form}
          expandRows={expandRows}
          expandRowsType={expandRowsType}
          paginationPerPage={paginationPerPage}
        />
      )}
      {showForm && (
        <ExternalDataForm
          {...props}
          title={'Filtro'}
          display_form={display_form}
          setLocalFormData={setLocalFormData}
        />
      )}
    </Container>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
PastaPesquisaView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
};

export default PastaPesquisaView;
