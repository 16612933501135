import React from 'react';
import { Icon, UniversalLink } from '@plone/volto/components';
import { Card } from 'semantic-ui-react';
import { Container } from '@plone/components';
import houseSVG from '@plone/volto/icons/home.svg';

const Header = () => {
  return <h2 className={'headline'}>Unidades Administrativas</h2>;
};

const AreasView = (props) => {
  const { className, content, isEditMode } = props;
  const { items } = content;
  return (
    <div className={`block areas ${className}`}>
      {isEditMode && !(items && items.length > 0) && <Header />}
      {items && items.length > 0 && (
        <>
          <Header />
          <Card.Group className={'subareas'}>
            {items.map(function (area, i) {
              return (
                <Card key={i}>
                  <Container className={'icon'}>
                    <Icon name={houseSVG} size="64px" />
                  </Container>
                  <Card.Content>
                    <Card.Header>
                      <UniversalLink href={area['@id']}>
                        {area.title}
                      </UniversalLink>
                    </Card.Header>
                    <Card.Meta>{area.description}</Card.Meta>
                  </Card.Content>
                </Card>
              );
            })}
          </Card.Group>
        </>
      )}
    </div>
  );
};

export default AreasView;
