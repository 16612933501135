/**
 * AdesaoAtaView view component.
 * @module components/View/Licitacao/AdesaoAtaView
 */
import React from 'react';
import { Container } from '@plone/components';
import { TableRow, TableCell, TableBody, Table } from 'semantic-ui-react';
import FileViewer from '../FileViewer/FileViewer';
import { CNPJWidgetDisplay } from '../Widgets/CNPJWidget';
import CurrencyWidget from '../Widgets/CurrencyWidget';
import { UniversalLink } from '@plone/volto/components';

const DadosBasicos = ({ content }) => {
  return (
    <Table className={'details'}>
      <TableBody>
        <TableRow>
          <TableCell className={'label'}>Órgão Gestor</TableCell>
          <TableCell className={'value'}>
            {content.orgao_gestor}
            {content.orgao_gestor_cnpj && (
              <>
                {' ('}
                <CNPJWidgetDisplay value={content.orgao_gestor_cnpj} />
                {')'}
              </>
            )}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell className={'label'}>Valor do contrato</TableCell>
          <TableCell className={'value'}>
            {content.valor_total && (
              <CurrencyWidget value={content.valor_total} />
            )}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell className={'label'}>Fornecedor</TableCell>
          <TableCell className={'value'}>
            {content.dados_fornecedor && (
              <UniversalLink item={content.dados_fornecedor}>
                {content.dados_fornecedor.title}
              </UniversalLink>
            )}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

/**
 * AdesaoAtaView view component class.
 * @function AdesaoAtaView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const AdesaoAtaView = ({ content }) => {
  const file = content.file;
  return (
    <Container narrow id="page-document" className="adesaoata-view">
      <h1 className="documentFirstHeading">{content.title}</h1>
      <h2 className={'headline'}>Objeto</h2>
      <p>{content.description}</p>
      <h2 className={'headline'}>Detalhes</h2>
      <DadosBasicos content={content} />
      {file && <FileViewer file={file} header={'Contrato'} />}
    </Container>
  );
};

export default AdesaoAtaView;
