/**
 * CEPWidget component.
 * @module components/Widgets/CEPWidget
 */
import React, { useState, useEffect, useRef } from 'react';
import FormFieldWrapper from '@plone/volto/components/manage/Widgets/FormFieldWrapper';
import PropTypes from 'prop-types';
import { mapToNumbers, validaCEP } from '../../helpers/validadores';
import { concat } from 'lodash';
import { Input } from 'semantic-ui-react';

const applyMask = (value) => {
  value = mapToNumbers(value).join('');
  let result = '';
  const mask = '99999-999';

  let inc = 0;
  if (value) {
    Array.from(value).forEach((letter, index) => {
      if (!mask[index + inc].match(/[0-9]/)) {
        result += mask[index + inc];
        inc++;
      }
      result += letter;
    });
  }
  return result;
};

const cleanUpValue = (value) => {
  return value !== undefined || value !== null
    ? value.replace(/[^0-9]/g, '')
    : '';
};

/** CEPWidget, a widget for cep value
 */
const CEPWidget = (props) => {
  const {
    id,
    value,
    focus,
    onChange,
    onBlur,
    onClick,
    minLength,
    maxLength,
    placeholder,
    isDisabled,
  } = props;
  const inputId = `field-${id}`;
  const ref = useRef();

  const [localValue, setLocalValue] = useState(value);
  const [maskedValue, setMaskedValue] = useState(applyMask(localValue));
  const [errors, setError] = useState([]);

  const fieldValidation = (value) => {
    const error = [];
    if (value && value.length !== 8) {
      error.push('O CEP deve ser no formato 99999-999');
    } else if (value && !validaCEP(value)) {
      error.push('O CEP informado não é válido');
    }
    setError(error);
  };

  const processValue = (rawValue) => {
    const value = cleanUpValue(rawValue);
    fieldValidation(value);
    setMaskedValue(applyMask(value));
    setLocalValue(value);
    return value;
  };

  useEffect(
    () => {
      if (focus) ref.current.focus();
      fieldValidation(value);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [focus, value],
  );

  props = {
    ...props,
    error: concat(props.error, errors),
  };

  const handleChange = ({ target }) => {
    const value = processValue(target.value);
    onChange(id, value === '' ? undefined : value);
  };

  const handleBlur = ({ target }) => {
    const value = processValue(target.value);
    onBlur(id, value === '' ? undefined : value);
  };

  return (
    <FormFieldWrapper {...props} className="cpf">
      <Input
        id={inputId}
        name={id}
        type="text"
        value={maskedValue || ''}
        disabled={isDisabled}
        placeholder={placeholder}
        onChange={handleChange}
        onBlur={handleBlur}
        onClick={() => onClick()}
        ref={ref}
        minLength={minLength || null}
        maxLength={maxLength || null}
      />
    </FormFieldWrapper>
  );
};

/**
 * Property types
 * @property {Object} propTypes Property types.
 * @static
 */
CEPWidget.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.arrayOf(PropTypes.string),
  focus: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onClick: PropTypes.func,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  placeholder: PropTypes.string,
};

/**
 * Default properties.
 * @property {Object} defaultProps Default properties.
 * @static
 */
CEPWidget.defaultProps = {
  description: null,
  required: false,
  error: [],
  value: null,
  focus: false,
  onChange: () => {},
  onBlur: () => {},
  onClick: () => {},
  minLength: 14,
  maxLength: 14,
};

const CEPWidgetDisplay = (props) => {
  const { value, className } = props;
  return <span className={`cep ${className}`}>{applyMask(value)}</span>;
};

export { CEPWidget, CEPWidgetDisplay };
