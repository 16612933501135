import { defineMessages } from 'react-intl';

const messages = defineMessages({
  colaboradores: {
    id: 'Equipe',
    defaultMessage: 'Equipe',
  },
  title: {
    id: 'Servidores',
    defaultMessage: 'Servidores',
  },
  filtrar: {
    id: 'Exibir apenas',
    defaultMessage: 'Exibir apenas',
  },
});

export const colaboradoresSchema = (props) => {
  return {
    title: props.intl.formatMessage(messages.colaboradores),
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['title', 'filtro'],
      },
    ],
    properties: {
      title: {
        title: props.intl.formatMessage(messages.title),
        default: 'Servidores',
      },
      filtro: {
        title: props.intl.formatMessage(messages.filtrar),
        widget: 'select',
        vocabulary: { '@id': 'transp_cmr.vocabulary.tipos_colaborador' },
      },
    },
    required: ['title', 'filtro'],
  };
};
