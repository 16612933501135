/**
 * DemonstracaoContabil view component.
 * @module components/Views/DemonstracaoContabilView
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Container as SemanticContainer } from 'semantic-ui-react';
import { UniversalLink } from '@plone/volto/components';
import config from '@plone/volto/registry';
import DataTable from 'react-data-table-component';

const demonstracaoColumns = [
  {
    name: 'Demonstração',
    selector: (row) => row.tipo,
    format: (row, index) => (
      <span className="title main">
        <UniversalLink item={row.documento}>{row.tipo.title}</UniversalLink>
      </span>
    ),
  },
];

const balancetesColumns = [
  {
    name: 'Período',
    selector: (row) => row.mes,
    format: (row, index) => (
      <span className="value detail">
        <UniversalLink item={row.documento}>{row.mes}</UniversalLink>
      </span>
    ),
  },
  {
    name: 'Link para Diário Oficial do Município',
    selector: (row) => row.do_texto,
    format: (row, index) => (
      <span className="value detail">
        <a
          href={row.do_link}
          title={row.do_link}
          target="_blank"
          rel="noreferrer"
        >
          {row.do_texto}
        </a>
      </span>
    ),
  },
];

/**
 * DemonstracaoContabil view component class.
 * @function DemonstracaoContabil
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const DemonstracaoContabilView = (props) => {
  const { content } = props;
  const Container =
    config.getComponent({ name: 'Container' }).component || SemanticContainer;
  const { title, description, tabela_documentos, tabela_balancetes } = content;

  return (
    <Container
      id="page-document"
      className="view-wrapper demonstracao_contabil-view"
    >
      <h1 className="documentFirstHeading">{title}</h1>
      {description && <p className="documentDescription">{description}</p>}
      {tabela_documentos.length > 0 && (
        <div className={'tabela documentos'}>
          <h2>Demonstrações Contábeis</h2>
          <DataTable columns={demonstracaoColumns} data={tabela_documentos} />
        </div>
      )}
      {tabela_balancetes.length > 0 && (
        <div className={'tabela balancetes'}>
          <h2>Balancete Financeiro Mensal</h2>
          <DataTable columns={balancetesColumns} data={tabela_balancetes} />
        </div>
      )}
    </Container>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
DemonstracaoContabilView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    tabela_repasses: PropTypes.array,
  }).isRequired,
};

export default DemonstracaoContabilView;
