/**
 * ExternalDataResults component.
 * @module components/ExternalDataResults/ExternalDataResults
 */
import React from 'react';
import { GridRow, GridColumn, Grid } from 'semantic-ui-react';
import DataTable from 'react-data-table-component';
import DownloadActions from '../DownloadActions/DownloadActions';

const paginationComponentOptions = {
  rowsPerPageText: 'Linhas por página',
  rangeSeparatorText: 'de',
  selectAllRowsItem: false,
  selectAllRowsItemText: 'Todos',
};

const processData = (data) => {
  const { items, totals } = data;
  const rows = items.map((item) => {
    return { ...item, _cat: 'item' };
  });
  const totalrows =
    totals === undefined
      ? []
      : totals.map((item) => {
          return { ...item, _cat: 'total' };
        });
  return [...rows, ...totalrows];
};

const SemDados = (props) => {
  return <div className={'empty'}>Nenhum resultado</div>;
};

const DrillDown = ({ data, columns, level }) => {
  level = level + 1;
  return (
    <div className={`level level-${level} drilldown`}>
      <DataTable
        columns={columns.main}
        data={data.items}
        dense
        noTableHead
        expandableRows
        expandableRowsComponent={DrillDown}
        expandableRowsComponentProps={{ columns, level }}
        expandableRowDisabled={(row) =>
          row?.items === undefined || row.items?.length === 0
        }
      />
    </div>
  );
};

const Details = ({ data, columns, displayHeader }) => {
  const props = {
    columns: columns.details,
    data: [data],
    dense: true,
    noTableHead: displayHeader ? null : 'noTableHead',
  };
  return (
    <div className={`row-details`}>
      <DataTable {...props} />
    </div>
  );
};

const Metadata = ({ metadata, data, display_form, showForm }) => {
  const { error } = metadata;
  return (
    <div className={'data-metadata'}>
      {error ? (
        <>
          <h2 className={'headline'}>Erro</h2>
          <Grid columns="equal" className={'metadata-details'}>
            <GridRow>
              <GridColumn textAlign={'center'}>
                <span className={'metadata-value'}>{error.msg}</span>
              </GridColumn>
            </GridRow>
          </Grid>
        </>
      ) : (
        <>
          <h2 className={'headline'}>{metadata.title}</h2>
          <Grid columns="equal" className={'metadata-details'}>
            {metadata.filters.map((filtro, idx) => (
              <GridRow key={`filtro-${idx}`}>
                <GridColumn textAlign={'left'} className={'metadata-label'}>
                  <span>{filtro.title}</span>
                </GridColumn>
                <GridColumn textAlign={'left'} className={'metadata-value'}>
                  <span>{filtro.value}</span>
                </GridColumn>
              </GridRow>
            ))}
            {metadata.updated_at && (
              <GridRow key={'updated_at'}>
                <GridColumn textAlign={'left'} className={'metadata-label'}>
                  <span>Última Atualização</span>
                </GridColumn>
                <GridColumn textAlign={'left'} className={'metadata-value'}>
                  <span>{metadata.updated_at}</span>
                </GridColumn>
              </GridRow>
            )}
            {metadata?.actions && (
              <GridRow>
                <GridColumn textAlign={'center'}>
                  <DownloadActions actions={metadata.actions} />
                </GridColumn>
              </GridRow>
            )}
            {display_form && (
              <GridRow>
                <GridColumn textAlign={'center'}>
                  <button
                    onClick={() => showForm()}
                    className={'ui button btn btn-info'}
                  >
                    Nova Consulta
                  </button>
                </GridColumn>
              </GridRow>
            )}
          </Grid>
        </>
      )}
    </div>
  );
};

const getExpandableRowsProps = (
  expandRowsType,
  expandRowsHeader,
  columns,
  level,
) => {
  const isDrillDown = expandRowsType === 'drilldown';
  const component = isDrillDown ? DrillDown : Details;
  const displayHeader = expandRowsHeader ? expandRowsHeader : false;
  return {
    expandableRows: true,
    expandableRowsComponent: component,
    expandableRowsComponentProps: { columns, level, displayHeader },
    expandableRowDisabled: isDrillDown
      ? (row) => row?.items === undefined || row.items?.length === 0
      : (row) => false,
  };
};

const conditionalRowStyles = [
  {
    when: (row) => row._cat === 'total',
    classNames: ['total', 'row'],
  },
];

const ExternalDataResults = (props) => {
  const {
    data,
    metadata,
    columns,
    display_form,
    showForm,
    expandRows,
    expandRowsType,
    expandRowsHeader,
    signature,
  } = props;
  const localData = processData(data);
  const level = 0;
  const paginationPerPage = props.paginationPerPage
    ? props.paginationPerPage
    : 30;
  let options = {};
  if (expandRows) {
    options = {
      ...options,
      ...getExpandableRowsProps(
        expandRowsType,
        expandRowsHeader,
        columns,
        level,
      ),
    };
  }
  if (localData && localData.length > paginationPerPage) {
    options['pagination'] = true;
    options['paginationComponentOptions'] = paginationComponentOptions;
    options['paginationPerPage'] = paginationPerPage;
    options['paginationRowsPerPageOptions'] = [10, 15, 20, 25, 30, 50, 100];
  }
  return (
    <div className={'externalDataResults'}>
      <Metadata
        metadata={metadata}
        data={data}
        display_form={display_form}
        showForm={showForm}
      />
      <div id={'results'} className={'resultados'}>
        <div className={'resultados-wrapper'}>
          <DataTable
            columns={columns.main}
            data={localData}
            noDataComponent={<SemDados />}
            conditionalRowStyles={conditionalRowStyles}
            dense
            {...options}
          />
        </div>
      </div>
      {signature && (
        <div id={'signature'}>
          <div className={'signature'}>
            <p className={'text'}>Informações fornecidas por</p>
            <a
              href={signature.link}
              target="_blank"
              className={'linkSignature'}
              rel="noreferrer"
            >
              <img src={signature.image} alt={signature.alt} />
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default ExternalDataResults;
