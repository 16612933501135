import React from 'react';

const LegislaturaGridItem = ({ item }) => {
  return (
    <div className={`card-container legislatura ${item.atual ? 'active' : ''}`}>
      <div className="item">
        <div className="content">
          {item?.head_title && (
            <div className="headline">{item.head_title}</div>
          )}
          <h2>{item?.title}</h2>
          <p>{item?.description}</p>
        </div>
      </div>
    </div>
  );
};

export default LegislaturaGridItem;
