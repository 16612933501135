import { defineMessages } from 'react-intl';

const messages = defineMessages({
  contato: {
    id: 'Contato',
    defaultMessage: 'Contato',
  },
  nome: {
    id: 'Nome',
    defaultMessage: 'Nome',
  },
  setor: {
    id: 'Setor',
    defaultMessage: 'Setor',
  },
  funcao: {
    id: 'Função',
    defaultMessage: 'Função',
  },
  telefone: {
    id: 'Telefone',
    defaultMessage: 'Telefone',
  },
  email: {
    id: 'E-mail',
    defaultMessage: 'E-mail',
  },
  endereco: {
    id: 'Endereço',
    defaultMessage: 'Endereço',
  },
  horario: {
    id: 'Horário',
    defaultMessage: 'Horário',
  },
});

export const contatoSchema = (props) => {
  return {
    title: props.intl.formatMessage(messages.contato),
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['nome', 'setor', 'funcao'],
      },
      {
        id: 'endereco',
        title: 'Endereço',
        fields: ['endereco', 'horario'],
      },
      {
        id: 'contato',
        title: 'Contato',
        fields: ['telefone', 'email'],
      },
    ],
    properties: {
      nome: {
        title: props.intl.formatMessage(messages.nome),
      },
      setor: {
        title: props.intl.formatMessage(messages.setor),
      },
      funcao: {
        title: props.intl.formatMessage(messages.funcao),
      },
      telefone: {
        title: props.intl.formatMessage(messages.telefone),
      },
      email: {
        title: props.intl.formatMessage(messages.email),
        widget: 'email',
      },
      endereco: {
        title: props.intl.formatMessage(messages.endereco),
        widget: 'textarea',
      },
      horario: {
        title: props.intl.formatMessage(messages.horario),
      },
    },
    required: ['nome', 'funcao', 'email', 'responsavel', 'endereco', 'horario'],
  };
};
