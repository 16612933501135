import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import RelatoriosAtividades from '../../RelatoriosAtividades/RelatoriosAtividades';

const RelatoriosAtividadesTemplate = (data) => {
  const pathname = useLocation().pathname;
  const { items, query } = data;
  return (
    <>
      <div className="items contratos-template">
        <RelatoriosAtividades
          documentos={items}
          params={query}
          url={pathname}
          allowExport={true}
        />
      </div>
    </>
  );
};

RelatoriosAtividadesTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  isEditMode: PropTypes.bool,
};

export default RelatoriosAtividadesTemplate;
