import React from 'react';
// SemanticUI-free pre-@plone/components

import { Container } from '@plone/components';
import { FormattedMessage } from 'react-intl';
import FooterLinks from '@plonegovbr/volto-network-block/components/FooterLinks/FooterLinks';

/**
 * Component to display the footer.
 * @function Footer
 * @returns {string} Markup of the component
 */
const Footer = () => {
  return (
    <footer id="footer">
      <Container className="footer">
        <div className="content-container">
          <div className="address-contact-container">
            <div className="socialNetworks">
              <p className="headline">Redes</p>
              <FooterLinks animate={false} align={'left'} />
            </div>
            <div className="address">
              <p className="headline">Endereço</p>
              <address className="addressText">
                Rua Princesa Isabel nº 410 - Boa Vista
                <br />
                50050-450 <br /> Recife / PE
                <br />{' '}
              </address>
            </div>
            <div className="contact">
              <p className="headline">Contato</p>
              <p>
                <FormattedMessage id="phone" defaultMessage="Fones" /> +55(81)
                3301-1256 / Fax (81) 3301-1262 <br />{' '}
                <FormattedMessage id="fax" defaultMessage="Fax" /> +55(81)
                3301-1262 <br />{' '}
                <span>De segunda a sexta-feira - das 7h30 às 18h</span>
              </p>
            </div>
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
