/**
 * TelefoneWidget component.
 * @module components/Widgets/TelefoneWidget
 */
import React, { useState, useEffect, useRef } from 'react';
import FormFieldWrapper from '@plone/volto/components/manage/Widgets/FormFieldWrapper';
import PropTypes from 'prop-types';
import { validaTelefone, mapToNumbers } from '../../helpers/validadores';
import { concat } from 'lodash';
import { Input } from 'semantic-ui-react';

const applyMask = (value) => {
  value = mapToNumbers(value).join('');
  let result = '';

  let inc = 0;
  if (value) {
    const mask = value.length === 11 ? '(99)99999-9999' : '(99)9999-9999';
    Array.from(value).forEach((letter, index) => {
      if (!mask[index + inc].match(/[0-9]/)) {
        result += mask[index + inc];
        inc++;
      }
      result += letter;
    });
  }
  return result;
};

const cleanUpValue = (value) => {
  return value !== undefined || value !== null
    ? value.replace(/[^0-9]/g, '')
    : '';
};

/** TelefoneWidget, a widget for cpf value
 */
const TelefoneWidget = (props) => {
  const {
    id,
    value,
    focus,
    onChange,
    onBlur,
    onClick,
    minLength,
    maxLength,
    placeholder,
    isDisabled,
  } = props;
  const inputId = `field-${id}`;
  const ref = useRef();

  const [localValue, setLocalValue] = useState(value);
  const [maskedValue, setMaskedValue] = useState(applyMask(localValue));
  const [errors, setError] = useState([]);

  const fieldValidation = (value) => {
    const error = [];
    if (value && value.length !== 10 && value.length !== 11) {
      error.push(
        'O Telefone deve ser no formato (99) 9999-999 ou (99) 99999-999',
      );
    } else if (value && !validaTelefone(value)) {
      error.push('O Telefone informado não é válido');
    }
    setError(error);
  };

  const processValue = (rawValue) => {
    const value = cleanUpValue(rawValue);
    fieldValidation(value);
    setMaskedValue(applyMask(value));
    setLocalValue(value);
    return value;
  };

  useEffect(
    () => {
      if (focus) ref.current.focus();
      fieldValidation(value);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [focus, value],
  );

  props = {
    ...props,
    error: concat(props.error, errors),
  };

  const handleChange = ({ target }) => {
    const value = processValue(target.value);
    onChange(id, value === '' ? undefined : value);
  };

  const handleBlur = ({ target }) => {
    const value = processValue(target.value);
    onBlur(id, value === '' ? undefined : value);
  };

  return (
    <FormFieldWrapper {...props} className="cpf">
      <Input
        id={inputId}
        name={id}
        type="text"
        value={maskedValue || ''}
        disabled={isDisabled}
        placeholder={placeholder}
        onChange={handleChange}
        onBlur={handleBlur}
        onClick={() => onClick()}
        ref={ref}
        minLength={minLength || null}
        maxLength={maxLength || null}
      />
    </FormFieldWrapper>
  );
};

/**
 * Property types
 * @property {Object} propTypes Property types.
 * @static
 */
TelefoneWidget.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.arrayOf(PropTypes.string),
  focus: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onClick: PropTypes.func,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  placeholder: PropTypes.string,
};

/**
 * Default properties.
 * @property {Object} defaultProps Default properties.
 * @static
 */
TelefoneWidget.defaultProps = {
  description: null,
  required: false,
  error: [],
  value: null,
  focus: false,
  onChange: () => {},
  onBlur: () => {},
  onClick: () => {},
  minLength: 14,
  maxLength: 14,
};

const TelefoneWidgetDisplay = (props) => {
  const { value, className } = props;
  return <span className={`telefone ${className}`}>{applyMask(value)}</span>;
};

export { TelefoneWidget, TelefoneWidgetDisplay };
