const estados = {
  criado: 'Criado',
  andamento: 'Em Andamento',
  concluido: 'Concluído',
  cancelado: 'Processo Fracassado',
  ativo: 'Ativo',
  inativo: 'Inativo',
  apenado: 'Apenado',
};

const Estado = ({ review_state }) => {
  const key = review_state?.token ? review_state.token : review_state;
  const label = review_state?.title ? review_state.title : estados[key];
  return <span className={`state state-${key}`}>{label}</span>;
};

export default Estado;
