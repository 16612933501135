import React from 'react';
import DataTable from 'react-data-table-component';
import ExportRow from '../ExportRow/ExportRow';
import { processColumns } from '../../helpers/columns';

const baseColumns = {
  main: [
    {
      id: 'parent.ano',
      style: 'text',
      title: 'Ano',
      sortable: false,
    },
    {
      id: 'title',
      style: 'text wrap',
      format: 'link',
      title: 'Contrato',
      sortable: false,
    },
    {
      id: 'parent.title',
      style: 'text wrap',
      format: 'link',
      title: 'Processo',
      sortable: false,
    },
    {
      id: 'description',
      style: 'text wrap',
      title: 'Objeto',
      sortable: false,
    },
    {
      id: 'valor_total',
      format: 'currency',
      title: 'Valor',
      sortable: false,
    },
    {
      id: 'dados_fornecedor.title',
      style: 'text wrap',
      title: 'Fornecedor',
      format: 'link',
      sortable: false,
    },
    {
      id: 'parent.gestores',
      style: 'text wrap',
      title: 'Gestores',
      format: 'listItems',
      sortable: false,
    },
    {
      id: 'parent.fiscais',
      style: 'text wrap',
      title: 'Fiscais',
      format: 'listItems',
      sortable: false,
    },
    {
      id: 'parent.start_contrato',
      title: 'Início',
      format: 'date',
      sortable: false,
    },
    {
      id: 'parent.end_contrato',
      title: 'Término',
      format: 'date',
      sortable: false,
    },
    {
      id: 'status',
      title: 'Estado',
      center: true,
      format: 'reviewState',
      sortable: false,
    },
  ],
};

const Contratos = ({ url, params, documentos, allowExport }) => {
  const columns = processColumns(baseColumns);
  return (
    <>
      <DataTable
        className={'contratos-items'}
        columns={columns['main']}
        data={documentos}
        dense
        striped
      />
      {allowExport && (
        <ExportRow
          url={url}
          params={params}
          endpoint={'@export-licitacao/contratos'}
        />
      )}
    </>
  );
};

export default Contratos;
