/**
 * ConcursoPublicoView view component.
 * @module components/View/Licitacao/ConcursoPublicoView
 */
import React from 'react';
import DataTable from 'react-data-table-component';
import { Container } from '@plone/components';
import { TableRow, TableCell, TableBody, Table } from 'semantic-ui-react';
import Estado from '../Estado/Estado';
import { processColumns } from '../../helpers/columns';
import LocalDate from '../LocalDate/LocalDate';
import FileViewer from '../FileViewer/FileViewer';

const items_columns = processColumns({
  main: [
    {
      id: 'title',
      style: 'text wrap',
      format: 'link',
      title: 'Anexo',
    },
    {
      id: 'description',
      style: 'text wrap',
      title: 'Descrição',
    },
    {
      id: 'file',
      style: 'text wrap',
      format: 'link_file',
      title: 'Arquivo',
    },
  ],
});

const DadosBasicos = ({ content }) => {
  return (
    <Table className={'details'}>
      <TableBody>
        <TableRow>
          <TableCell className={'label'}>Ano</TableCell>
          <TableCell className={'value'}>{content.ano}</TableCell>
        </TableRow>
        {content.end && (
          <TableRow>
            <TableCell className={'label'}>Validade</TableCell>
            <TableCell className={'value'}>
              <LocalDate date={content.end} />
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

/**
 * ConcursoPublicoView view component class.
 * @function ConcursoPublicoView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const ConcursoPublicoView = ({ content }) => {
  const file = content.file;
  return (
    <Container narrow id="page-document" className="concursopublico-view">
      <div className={'header-estado'}>
        <h1 className="documentFirstHeading">{content.title}</h1>
        <Estado review_state={content.status} />
      </div>
      <h2 className={'headline'}>Descrição</h2>
      <p>{content.description}</p>
      <h2 className={'headline'}>Detalhes</h2>
      <DadosBasicos content={content} />
      {file && <FileViewer file={file} header={'Edital'} />}
      {content.anexos && content.anexos.length > 0 && (
        <>
          <h2 className={'headline'}>Anexos</h2>
          <DataTable
            columns={items_columns.main}
            data={content.anexos}
            striped
          />
        </>
      )}
    </Container>
  );
};

export default ConcursoPublicoView;
