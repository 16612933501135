import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'Formulário Dados Externos',
    defaultMessage: 'Formulário Dados Externos',
  },
  formTitle: {
    id: 'Título',
    defaultMessage: 'Título',
  },
});

export const localFormSchema = (props) => {
  return {
    title: props.intl.formatMessage(messages.title),
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['title'],
      },
    ],
    properties: {
      title: {
        title: props.intl.formatMessage(messages.formTitle),
      },
    },
    required: ['title'],
  };
};
