/**
 * SessaoVotacoes view component.
 * @module components/Views/SessaoVotacoes
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Container } from '@plone/components';
import { TableRow, TableCell, TableBody, Table } from 'semantic-ui-react';
import ContentAccordion from '../ContentAccordion/ContentAccordion';
import DataTable from 'react-data-table-component';
import { processColumns } from '../../helpers/columns';

const columns = processColumns({
  main: [
    {
      id: 'title',
      style: 'text',
      title: 'Vereador',
      format: 'link',
      sortable: true,
    },
    {
      id: 'partido',
      title: 'Partido',
      style: 'text',
      format: 'vocabulary_list',
      sortable: true,
      sortKey: 'title',
    },
    {
      id: 'state',
      title: 'Voto',
      format: 'vocabulary',
      style: 'pill',
      center: true,
      sortable: true,
      sortKey: 'token',
    },
  ],
});

const Apuracao = ({ apuracao }) => {
  const { presidencia, favoravel, contrario, abstencao, ausente, votos } =
    apuracao;
  return (
    <Container className={'materia apuracao'}>
      <Table className={'details apuracao'}>
        <TableBody>
          <TableRow>
            <TableCell className={'label center'}>Presidência</TableCell>
            <TableCell className={'label center'}>Favorável</TableCell>
            <TableCell className={'label center'}>Contrário</TableCell>
            <TableCell className={'label center'}>Abstenção</TableCell>
            <TableCell className={'label center'}>Ausente</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={'value center'}>{presidencia}</TableCell>
            <TableCell className={'value center'}>{favoravel}</TableCell>
            <TableCell className={'value center'}>{contrario}</TableCell>
            <TableCell className={'value center'}>{abstencao}</TableCell>
            <TableCell className={'value center'}>{ausente}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <DataTable columns={columns.main} data={votos} striped />
    </Container>
  );
};

const MateriaInfo = ({ content }) => {
  const { apuracao } = content;
  return (
    <Container className={'materia'}>
      <p>{content.description}</p>
      <Table className={'details apuracao'}>
        <TableBody>
          <TableRow>
            <TableCell className={'label'}>Autoria</TableCell>
            <TableCell className={'value'}>
              {content.autoria ? content.autoria[0].title : '-'}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={'label'}>Tipo de votação</TableCell>
            <TableCell className={'value'}>{content.tipo_votacao}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={'label'}>Quorum</TableCell>
            <TableCell className={'value'}>{content.quorum}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={'label'}>Turno</TableCell>
            <TableCell className={'value'}>{content.turno}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={'label'}>Resultado</TableCell>
            <TableCell
              className={`value resultado ${content.apuracao?.resultado}`}
            >
              {content.apuracao?.resultado ? content.apuracao.resultado : '-'}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Apuracao apuracao={apuracao} />
    </Container>
  );
};

/**
 * Votações Sessao view component class.
 * @function SessaoVotacoes
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const SessaoVotacoes = ({ content }) => {
  return (
    <Container id="votacoes" className="sessao votacoes">
      <h2 className="headline">Votações</h2>
      <Container className={'votacoes'}>
        {content.items && content.items.length > 0 ? (
          <ContentAccordion items={content.items} Component={MateriaInfo} />
        ) : (
          <Container className={'noresults'}>
            <span>Sem votações</span>
          </Container>
        )}
      </Container>
    </Container>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
SessaoVotacoes.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
};

export default SessaoVotacoes;
