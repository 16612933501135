import React from 'react';

const MesaDiretoraGridItem = ({ item }) => {
  return (
    <div className="card-container mesa-diretora">
      <div className="item">
        <div className="content">
          {item?.head_title && (
            <div className="headline">{item.head_title}</div>
          )}
          <h2>Legislatura {item?.legislatura}</h2>
          <p>{item?.description}</p>
        </div>
      </div>
    </div>
  );
};

export default MesaDiretoraGridItem;
