import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import Documentos from '../../Documentos/Documentos';

const DocumentosTemplate = (data) => {
  const pathname = useLocation().pathname;
  const { items, query } = data;
  return (
    <>
      <div className="items documentos-template">
        <Documentos
          documentos={items}
          params={query}
          url={pathname}
          allowExport={true}
        />
      </div>
    </>
  );
};

DocumentosTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  isEditMode: PropTypes.bool,
};

export default DocumentosTemplate;
