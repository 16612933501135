/**
 * PresencaSessao view component.
 * @module components/Views/PresencaSessao
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Container } from '@plone/components';
import ContentAccordion from '../ContentAccordion/ContentAccordion';
import DataTable from 'react-data-table-component';
import { processColumns } from '../../helpers/columns';

const columns = processColumns({
  main: [
    {
      id: 'title',
      style: 'text',
      title: 'Vereador',
      format: 'link',
      sortable: true,
    },
    {
      id: 'partido',
      title: 'Partido',
      style: 'text',
      format: 'vocabulary_list',
      sortable: true,
      sortKey: 'title',
    },
    {
      id: 'state',
      title: 'Presença',
      format: 'vocabulary',
      style: 'pill',
      center: true,
      sortable: true,
      sortKey: 'token',
    },
  ],
});

const PresencaChamada = (props) => {
  const { content } = props;
  return (
    <Container className={'chamada'}>
      <DataTable columns={columns.main} data={content.items} striped />
    </Container>
  );
};

/**
 * Presenção Sessão view component class.
 * @function PresencaSessao
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const PresencaSessao = ({ content }) => {
  const items = [
    { title: 'Chamada Regimental', items: content.chamadaRegimental },
    { title: 'Ordem do Dia', items: content.ordemDia },
  ];
  return (
    <Container id="presenca" className="sessao presenca">
      <h2 className="headline">{content.title}</h2>
      <Container className={'chamadas'}>
        <ContentAccordion items={items} Component={PresencaChamada} />
      </Container>
    </Container>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
PresencaSessao.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
};

export default PresencaSessao;
