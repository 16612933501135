import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@plone/volto/components';
import clockSvg from '../../../icons/business-time-solid.svg';
import emailSvg from '../../../icons/envelope-solid.svg';
import phoneSvg from '../../../icons/phone-solid.svg';
import locationSvg from '../../../icons/location-dot-solid.svg';

const ContatoView = (props) => {
  const { className, nome, setor, funcao, telefone, email, endereco, horario } =
    props;
  return (
    <div className={`block contato ${className}`}>
      <div className={'contatoWrapper'}>
        {setor && <div className="info setor">{setor}</div>}
        <div className="info nome">{nome}</div>
        <div className="info funcao">{funcao}</div>
        <div className={'colunas'}>
          <div className={'coluna'}>
            {endereco && (
              <div className="info endereco">
                <Icon name={locationSvg} size={'16px'} />{' '}
                <span>{endereco}</span>
              </div>
            )}
            <div className="info horario">
              {horario && (
                <>
                  <Icon name={clockSvg} size={'16px'} /> <span>{horario}</span>
                </>
              )}
            </div>
          </div>
          <div className={'coluna'}>
            <div className="info telefone">
              {telefone && (
                <>
                  <Icon name={phoneSvg} size={'16px'} /> <span>{telefone}</span>
                </>
              )}
            </div>
            <div className="info email">
              {email && (
                <>
                  <Icon name={emailSvg} size={'16px'} />{' '}
                  <a
                    href={`mailto:${email}`}
                    title="email"
                    className={'info email'}
                  >
                    {email}
                  </a>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
ContatoView.propTypes = {
  Contato: PropTypes.string,
};

export default ContatoView;
