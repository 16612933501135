/**
 * Legislatura view component.
 * @module components/Views/LegislaturaView
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Container } from '@plone/components';
import ProfilesTemplate from '../../Blocks/Listing/ProfilesTemplate';
import { TableRow, TableCell, TableBody, Table } from 'semantic-ui-react';

const Details = ({ content }) => {
  return (
    <Container className={'details'}>
      <Table className={'details'}>
        <TableBody>
          <TableRow>
            <TableCell className={'label'}>Eleição</TableCell>
            <TableCell className={'value'}>{content.data_eleicao}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={'label'}>Início</TableCell>
            <TableCell className={'value'}>
              {content.start ? content.start : '-'}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={'label'}>Término</TableCell>
            <TableCell className={'value'}>
              {content.end ? content.end : '-'}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Container>
  );
};

/**
 * Legislatura view component class.
 * @function LegislaturaView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const LegislaturaView = (props) => {
  const { content } = props;
  const { title, items } = content;
  const vereadores = Object.values(
    items.reduce((obj, item) => {
      item['cargo'] = item['mandato'];
      obj[item['title']] = item;
      return obj;
    }, {}),
  );
  return (
    <Container narrow id="page-document" className="view-wrapper vereador-view">
      <h1 className="documentFirstHeading">{title}</h1>
      <Details content={content} />
      <div id={'vereadores'} className={'block listing profiles'}>
        <h2 className={'headline'}>Composição</h2>
        <ProfilesTemplate items={vereadores} />
      </div>
    </Container>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
LegislaturaView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
};

export default LegislaturaView;
