import { UniversalLink } from '@plone/volto/components';
import CurrencyWidget from '../components/Widgets/CurrencyWidget';
import RelationWidget from '../components/Widgets/RelationWidget';
import { DownloadLink } from '../components/DownloadActions/DownloadActions';
import Estado from '../components/Estado/Estado';
import LocalDate from '../components/LocalDate/LocalDate';
import { CNPJWidgetDisplay } from '../components/Widgets/CNPJWidget';
import { CPFWidgetDisplay } from '../components/Widgets/CPFWidget';
import { CEPWidgetDisplay } from '../components/Widgets/CEPWidget';
import { validaCPF } from './validadores';

const vocabSort = (id, key) => {
  return (rowA, rowB) => {
    const a = Array.isArray(rowA[id]) ? rowA[id]?.[0]?.[key] : rowA[id]?.[key];
    const b = Array.isArray(rowB[id]) ? rowB[id]?.[0]?.[key] : rowB[id]?.[key];
    if (a > b) {
      return 1;
    }
    if (b > a) {
      return -1;
    }
    return 0;
  };
};

const prepareFormat = (item, raw_row, index) => {
  const { format, style } = item;
  const hasSubItem = /\./.test(item.id);
  const row = hasSubItem ? raw_row[item.id.split('.')[0]] : raw_row;
  const id = hasSubItem ? item.id.split('.')[1] : item.id;
  const emptyValue = item.emptyValue ? item.emptyValue : ' - ';
  if (format === undefined) {
    return <span className={`${style} column`}>{row[id]}</span>;
  } else if (format === 'vocabulary') {
    const value = row[id];
    const token = value?.token;
    const title = value?.title;
    return (
      <span className={`${style} column column-${id} value-${token}`}>
        {title ? title : emptyValue}
      </span>
    );
  } else if (format === 'vocabulary_list') {
    const value = row[id] ? row[id][0] : null;
    const token = value?.token;
    const title = value?.title;
    return (
      <span className={`${style} column column-${id} value-${token}`}>
        {title ? title : emptyValue}
      </span>
    );
  } else if (format === 'link_file') {
    const value = row[id];
    const format = 'pdf';
    const href = value?.download;
    const title = 'Baixar';
    return (
      <span className={`${style} link`}>
        {href && <DownloadLink format={format} title={title} href={href} />}
      </span>
    );
  } else if (format === 'link') {
    return (
      <span className={`${style} link`}>
        {row && row[id] ? (
          <UniversalLink item={row}>{row[id]}</UniversalLink>
        ) : (
          emptyValue
        )}
      </span>
    );
  } else if (format === 'relation') {
    return (
      <span className={`${style} link`}>
        <RelationWidget value={row[id]} />
      </span>
    );
  } else if (format === 'date') {
    const value = row[id];
    return (
      <span className={`${style} date`}>
        {value ? <LocalDate date={row[id]} /> : <span>-</span>}
      </span>
    );
  } else if (format === 'listItems') {
    return (
      <div className={`${style} list`}>
        {row?.[id]?.length > 0 &&
          row?.[id].map((elem, idx) => (
            <span className="main" key={idx}>
              {elem.title ? elem.title : elem}
            </span>
          ))}
      </div>
    );
  } else if (format === 'currency') {
    const value = row[id];
    return (
      <span className={`${style} currency`}>
        {value !== undefined && value !== null ? (
          <CurrencyWidget value={value} />
        ) : (
          emptyValue
        )}
      </span>
    );
  } else if (format === 'cpf_cnpj') {
    const value = row[id];
    const Component = validaCPF(value) ? CPFWidgetDisplay : CNPJWidgetDisplay;
    return value !== undefined && value !== null ? (
      <Component className={style} value={value} />
    ) : (
      <span className={`${style} cnpj`}>{emptyValue}</span>
    );
  } else if (format === 'cnpj') {
    const value = row[id];
    return value !== undefined && value !== null ? (
      <CNPJWidgetDisplay className={style} value={value} />
    ) : (
      <span className={`${style} cnpj`}>{emptyValue}</span>
    );
  } else if (format === 'cpf') {
    const value = row[id];
    return value !== undefined && value !== null ? (
      <CPFWidgetDisplay className={style} value={value} />
    ) : (
      <span className={`${style} cpf`}>{emptyValue}</span>
    );
  } else if (format === 'cep') {
    const value = row[id];
    return value !== undefined && value !== null ? (
      <CEPWidgetDisplay className={style} value={value} />
    ) : (
      <span className={`${style} cep`}>{emptyValue}</span>
    );
  } else if (format === 'pre') {
    return <pre className={`${style} pre`}>{row[id]}</pre>;
  } else if (format === 'reviewState') {
    return <Estado review_state={row[id]} />;
  }
};

export const processColumns = (configColumns) => {
  const columnsMapping = Object.entries(configColumns).map(
    ([key, columnsGroup]) => {
      const columns = columnsGroup.map((item) => {
        const { title, id, right, center, style, grow, width, sortable } = item;
        const sortFunction = item.sortKey
          ? vocabSort(id, item.sortKey)
          : undefined;
        return {
          name: title,
          selector: (row) => row[id],
          format: (row, index) => prepareFormat(item, row, index),
          right: right ? 'right' : '',
          center: center ? 'center' : '',
          grow: grow,
          width: width,
          sortable: sortable ? true : false,
          sortFunction: sortFunction,
        };
      });
      return [key, columns];
    },
  );
  return Object.fromEntries(columnsMapping);
};
