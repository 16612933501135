/**
 * ConvenioView view component.
 * @module components/View/Licitacao/ConvenioView
 */
import React from 'react';
import DataTable from 'react-data-table-component';
import { Container } from '@plone/components';
import { TableRow, TableCell, TableBody, Table } from 'semantic-ui-react';
import Estado from '../Estado/Estado';
import { processColumns } from '../../helpers/columns';
import LocalDate from '../LocalDate/LocalDate';
import CurrencyWidget from '../Widgets/CurrencyWidget';
import { CNPJWidgetDisplay } from '../Widgets/CNPJWidget';

const items_columns = processColumns({
  main: [
    {
      id: 'title',
      style: 'text wrap',
      format: 'link',
      title: 'Aditivo',
    },
    {
      id: 'description',
      style: 'text wrap',
      title: 'Descrição',
    },
    {
      id: 'file',
      style: 'text wrap',
      format: 'link_file',
      title: 'Arquivo',
    },
  ],
});

const DadosBasicos = ({ content }) => {
  const fornecedor = content.dados_fornecedor?.title
    ? content.dados_fornecedor
    : null;
  return (
    <Table className={'details'}>
      <TableBody>
        <TableRow>
          <TableCell className={'label'}>Exercício</TableCell>
          <TableCell className={'value'}>{content.ano}</TableCell>
        </TableRow>
        {fornecedor && (
          <>
            <TableRow>
              <TableCell className={'label'}>Fornecedor</TableCell>
              <TableCell className={'value'}>{fornecedor.title}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={'label'}>CNPJ</TableCell>
              <TableCell className={'value'}>
                <CNPJWidgetDisplay value={fornecedor.cnpj} />
              </TableCell>
            </TableRow>
          </>
        )}
        {content.data_assinatura && (
          <TableRow>
            <TableCell className={'label'}>Data da assinatura</TableCell>
            <TableCell className={'value'}>
              <LocalDate date={content.data_assinatura} />
            </TableCell>
          </TableRow>
        )}
        {content.start && (
          <TableRow>
            <TableCell className={'label'}>Início do convênio</TableCell>
            <TableCell className={'value'}>
              <LocalDate date={content.start} />
            </TableCell>
          </TableRow>
        )}
        {content.end && (
          <TableRow>
            <TableCell className={'label'}>Término do convênio</TableCell>
            <TableCell className={'value'}>
              <LocalDate date={content.end} />
            </TableCell>
          </TableRow>
        )}
        {content.valor_previsto && (
          <TableRow>
            <TableCell className={'label'}>Valor previsto</TableCell>
            <TableCell className={'value'}>
              <CurrencyWidget value={content.valor_previsto} />
            </TableCell>
          </TableRow>
        )}
        {content.valor_concedido && (
          <TableRow>
            <TableCell className={'label'}>Valor concedido</TableCell>
            <TableCell className={'value'}>
              <CurrencyWidget value={content.valor_concedido} />
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

/**
 * ConvenioView view component class.
 * @function ConvenioView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const ConvenioView = ({ content }) => {
  return (
    <Container narrow id="page-document" className="convenio-view">
      <div className={'header-estado'}>
        <h1 className="documentFirstHeading">{content.title}</h1>
        <Estado review_state={content.status} />
      </div>
      <h2 className={'headline'}>Objeto</h2>
      <p>{content.description}</p>
      <h2 className={'headline'}>Detalhes</h2>
      <DadosBasicos content={content} />
      {content.aditivos && content.aditivos.length > 0 && (
        <>
          <h2 className={'headline'}>Aditivos</h2>
          <DataTable
            columns={items_columns.main}
            data={content.aditivos}
            striped
          />
        </>
      )}
    </Container>
  );
};

export default ConvenioView;
