import React from 'react';
import DataTable from 'react-data-table-component';
import ExportRow from '../ExportRow/ExportRow';
import { processColumns } from '../../helpers/columns';

const baseColumns = {
  main: [
    {
      id: 'ano',
      style: 'text',
      title: 'Ano',
      sortable: false,
    },
    {
      id: 'title',
      style: 'text wrap',
      format: 'link',
      title: 'Processo',
      sortable: false,
    },
    {
      id: 'modalidade',
      style: 'text wrap',
      title: 'Modalidade',
      format: 'vocabulary',
      sortable: false,
    },
    {
      id: 'description',
      style: 'text wrap',
      title: 'Objeto',
      sortable: false,
    },
    {
      id: 'valor_estimado',
      format: 'currency',
      title: 'Valor estimado',
      sortable: false,
    },
    {
      id: 'valor_homologado',
      format: 'currency',
      title: 'Valor homologado',
      sortable: false,
    },
    {
      id: 'review_state',
      style: 'text wrap',
      title: 'Situação',
      format: 'reviewState',
      sortable: false,
    },
  ],
};

const Contratacoes = ({ url, params, contratacoes, allowExport }) => {
  const columns = processColumns(baseColumns);
  return (
    <>
      <DataTable
        className={'contratacoes-items'}
        columns={columns['main']}
        data={contratacoes}
        dense
        striped
      />
      {allowExport && (
        <ExportRow
          url={url}
          params={params}
          endpoint={'@export-licitacao/contratacoes'}
        />
      )}
    </>
  );
};

export default Contratacoes;
