import { has } from 'lodash';
import { GET_VEREADOR_DATA } from '../constants/ActionTypes';

const initialState = {
  loaded: false,
  loading: false,
  error: null,
  subrequests: {},
};

export const vereadorData = (state = initialState, action = {}) => {
  switch (action.type) {
    case `${GET_VEREADOR_DATA}_PENDING`:
      return action.subrequest
        ? {
            ...state,
            subrequests: {
              ...state.subrequests,
              [action.subrequest]: {
                ...(state.subrequests[action.subrequest] || {
                  data: {},
                }),
                loaded: false,
                loading: true,
                error: null,
              },
            },
          }
        : {
            ...state,
            error: null,
            loading: true,
            loaded: false,
          };
    case `${GET_VEREADOR_DATA}_SUCCESS`:
      return action.subrequest
        ? {
            ...state,
            subrequests: {
              ...state.subrequests,
              [action.subrequest]: {
                ...(state.subrequests[action.subrequest] || {
                  data: {},
                }),
                error: null,
                loading: false,
                loaded: true,
                data: has(action, 'result') ? { ...action.result } : {},
              },
            },
          }
        : {
            ...state,
            error: null,
            loading: false,
            loaded: true,
            data: has(action, 'result') ? { ...action.result } : {},
          };
    case `${GET_VEREADOR_DATA}_FAIL`:
      return action.subrequest
        ? {
            ...state,
            subrequests: {
              ...state.subrequests,
              [action.subrequest]: {
                ...(state.subrequests[action.subrequest] || {
                  data: {},
                }),
                error: action.error,
                loading: false,
                loaded: false,
                data: {},
              },
            },
          }
        : {
            ...state,
            error: action.error,
            loading: false,
            loaded: false,
            data: {},
          };
    default:
      return state;
  }
};
