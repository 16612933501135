import { GET_VEREADOR_DATA } from '../../constants/ActionTypes';

export function getVereadorData(cod_vereador) {
  const path = `/legislativo/vereadores/item/${cod_vereador}`;
  return {
    type: GET_VEREADOR_DATA,
    subrequest: cod_vereador,
    request: {
      op: 'get',
      path: `${path}`,
    },
  };
}
