import React from 'react';
import PropTypes from 'prop-types';
import GestaoFiscal from '../../GestaoFiscal/GestaoFiscal';
import { useLocation } from 'react-router-dom';

const RelatorioGestaoFiscalTemplate = (data) => {
  const pathname = useLocation().pathname;
  const { items, query, querystring } = data;
  const params = query ? query : querystring;
  return (
    <>
      <div className="items relatorios-gestao-fiscal-template">
        <GestaoFiscal
          relatorios={items}
          params={params}
          url={pathname}
          allowExport={true}
        />
      </div>
    </>
  );
};

RelatorioGestaoFiscalTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  isEditMode: PropTypes.bool,
};

export default RelatorioGestaoFiscalTemplate;
