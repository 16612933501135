/**
 * SessaoVotacoes view component.
 * @module components/Views/SessaoVotacoes
 */
import React, { useState } from 'react';
import { Icon } from '@plone/volto/components';
import { Accordion } from 'semantic-ui-react';
import upSVG from '@plone/volto/icons/up-key.svg';
import downSVG from '@plone/volto/icons/down-key.svg';

const titleIcons = {
  closed: upSVG,
  opened: downSVG,
  size: '24px',
  iconComponent: 'VoltoIcon',
};

const ContentAccordion = ({ items, Component }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const handleClick = (e, titleProps) => {
    const { idx } = titleProps;
    const newIndex = activeIndex === idx ? -1 : idx;
    setActiveIndex(newIndex);
  };
  return (
    items &&
    items.map((item, idx) => {
      const active = activeIndex === idx;
      return (
        <Accordion styled fluid className={'contentaccordion'} key={idx}>
          <Accordion.Title
            idx={idx}
            className={'align-arrow-right accordion-title'}
            active={active}
            onClick={handleClick}
          >
            <Icon
              options={titleIcons}
              name={active ? titleIcons.opened : titleIcons.closed}
            />
            <span>{item.title}</span>
          </Accordion.Title>
          <Accordion.Content active={activeIndex === idx}>
            <Component content={item} />
          </Accordion.Content>
        </Accordion>
      );
    })
  );
};

export default ContentAccordion;
