/**
 * InformacaoEmprel view component.
 * @module components/Views/InformacaoEmprelView
 */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Container as SemanticContainer } from 'semantic-ui-react';
import config from '@plone/volto/registry';
import { hasBlocksData, getBaseUrl } from '@plone/volto/helpers';
import RenderBlocks from '@plone/volto/components/theme/View/RenderBlocks';
import ExternalDataForm from '../ExternalDataForm/ExternalDataForm';
import ExternalDataResults from '../ExternalDataResults/ExternalDataResults';
import NextPrevious from '../NextPrevious/NextPrevious';
import { processColumns } from '../../helpers/columns';
import logoEmprel from '../../images/emprel.png';

const prepData = (localData) => {
  return localData?.data ? localData.data : localData?.items ? localData : {};
};

const InformacaoEmprelView = (props) => {
  const { content, location } = props;
  const Container =
    config.getComponent({ name: 'Container' }).component || SemanticContainer;
  const path = getBaseUrl(location?.pathname || '');
  const { title, description, data, view_config, display_form } = content;
  const [showForm, setShowForm] = useState(display_form);
  const [localData, setLocalData] = useState();

  useEffect(() => {
    setLocalData(data);
  }, [data, setLocalData]);

  const displayForm = () => {
    setShowForm(true);
    setLocalData({});
  };

  const setLocalFormData = (data) => {
    if (data?.data?.items) {
      setLocalData(data);
      setShowForm(false);
    }
  };
  const pagination = view_config?.display?.pagination;
  const paginationPerPage = pagination?.paginationPerPage;
  const columns = processColumns(view_config?.display?.columns);
  const expandRows = view_config?.display?.expandRows;
  const expandRowsType = view_config?.display?.expandRowsType;
  const metadata = localData?.metadata;
  return (
    <Container
      id="page-document"
      className="view-wrapper informacaoemprel-view"
    >
      {hasBlocksData(content) ? (
        <RenderBlocks {...props} path={path} />
      ) : (
        <>
          <h1 className="documentFirstHeading">{title}</h1>
          {description && <p className="documentDescription">{description}</p>}
        </>
      )}
      {!showForm && metadata && (
        <ExternalDataResults
          data={prepData(localData)}
          metadata={metadata}
          columns={columns}
          showForm={displayForm}
          display_form={display_form}
          expandRows={expandRows}
          expandRowsType={expandRowsType}
          paginationPerPage={paginationPerPage}
          signature={{
            link: 'https://www.emprel.gov.br/',
            image: logoEmprel,
            alt: 'Logo Emprel',
          }}
        />
      )}
      {showForm && display_form && (
        <ExternalDataForm
          {...props}
          title={'Filtro'}
          setLocalFormData={setLocalFormData}
        />
      )}
      <NextPrevious content={content} allowedTypes={'InformacaoEmprel'} />
    </Container>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
InformacaoEmprelView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
};

export default InformacaoEmprelView;
