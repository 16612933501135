const CurrencyWidget = ({ value }) => {
  const isValid = value !== undefined && value !== null;
  const formattedValue = isValid
    ? new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).format(value)
    : '-';
  return <>{formattedValue}</>;
};

export default CurrencyWidget;
