import { UniversalLink } from '@plone/volto/components';

const RelationWidget = ({ value }) => {
  const isValid = value && value['@id'];
  return isValid ? (
    <UniversalLink item={value}>{value.title}</UniversalLink>
  ) : (
    '-'
  );
};

export default RelationWidget;
